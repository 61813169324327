import { whiteColor, primaryColour, dangerColor } from "assets/jss/material-dashboard-react"

const integrationStyle = theme => ({
  integrationIndex: {
    zIndex: 1,
    position: "fixed"
  },
  integrationComponent: {
    width: "100vw",
    height: "100vh",
    background: "linear-gradient(0deg, rgba(195,179,218,1) 0%, rgba(162,198,223,1) 100%)",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  panelWrapper: {
    transform: "translate3d(0, 0, 0)",
    transition:"transform 0.5s ease-in-out",
    position: "absolute",
    zIndex: 3
  },
  panelFullScreen: {
    transform: "translate3d(-460px, 0, 0)"
  },
  panelOne: {
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 3,
    width: "calc(500px - 40px)",
    borderTopRightRadius: 15,
    height: "calc(100vh - 40px)",
    backgroundColor: whiteColor,
    // boxShadow: "0 3px 10px rgba(0,0,0,0.3)",
    paddingTop: 40,
    paddingRight: 20,
    paddingBottom: 0,
    paddingLeft: 20,
    transform: "translate3d(0, 0, 0)",
    transition:"transform 0.5s ease-in-out"
  },
  panelOneHide: {
    transform: "translate3d(-460px, 0, 0)"
  },
  panelOneWrapper: {
    position:"relative",
    height: "calc(100% - 85px)",
    paddingTop: 0,
    paddingRight: 30,
    paddingBottom: 0,
    paddingLeft: 20,
    marginBottom: 0
  },
  panelTwo: {
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 10,
    width: "100vw",
    height: "100vh",
    backgroundColor: primaryColour[50],
    padding: 0,
  },
  panelTwoWrapper:{
    paddingTop: 80,
    paddingRight:15,
    paddingBottom:40,
    paddingLeft:15
  },
  closeFab: {
    backgroundColor: whiteColor,
    right: 35,
    top: 35,
    position: "fixed"
  },
  contentTable: {
    maxWidth: 560,
    margin: "0 auto",
    width: "100%"
  },
  standardForm: {
    marginTop: 20,
    marginRight: 0,
    marginBottom: 87,
    marginLeft: 0,
  },
  stepperIndex: {
    zIndex: 4,
    position: "fixed",
    bottom: 0,
    backgroundColor: primaryColour[900],
    width: "calc(100vw - 80px)",
    padding: "0 40px",
    transform: "translate3d(0, 0, 0)",
    transition:"transform 0.5s ease-in-out"
  },
  stepperHidden: {
    transform: "translate3d(0, 65px, 0)",
  },
  customStepper: {
    backgroundColor: "transparent",
    padding: "20px 10px 10px 10px",
    "& .MuiStepIcon-root": {
      color: primaryColour[600]
    },
    "& .MuiStepLabel-label": {
      color: primaryColour[600],
      marginTop: 5
    },
    "& .MuiStepConnector-line": {
      borderColor: primaryColour[600]
    },
    "& .MuiStepConnector-active .MuiStepConnector-line, & .MuiStepConnector-completed .MuiStepConnector-line": {
      borderColor: whiteColor
    },
    "& .MuiStepIcon-root.MuiStepIcon-active, & .MuiStepIcon-root.MuiStepIcon-completed": {
      color: whiteColor
    },
    "& .MuiStepIcon-root.MuiStepIcon-active .MuiStepIcon-text, & .MuiStepIcon-root.MuiStepIcon-completed .MuiStepIcon-text": {
      fill: primaryColour[900]
    },
    "& .MuiStepLabel-label.MuiStepLabel-active, & .MuiStepLabel-label.MuiStepLabel-completed": {
      color: whiteColor
    }
  },
  integrationWrapper: {
    position: "realtive"
  },
  subHeadOne: {
    paddingTop: 10,
    paddingBottom: 10,
    color: primaryColour[800],
    marginTop: -5
  },
  customRadio: {
    minWidth: 130,
    "& .MuiButtonBase-root": {
      padding: "4px"
    },
    "& .MuiTypography-root": {
      fontSize: 14,
      color: "rgba(0, 0, 0, 0.54)"
    },
    "& .MuiButtonBase-root + .MuiTypography-root": {
      color: primaryColour[900]
    }
  },
  customDividerOne: {
    marginTop: -10,
    marginBottom: 8
  },
  customDividerTwo: {
    marginTop: 10,
    marginBottom: 10
  },
  buttonSpacing: {
    marginTop: 10,
    marginBottom: 10
  },
  integrationTable: {
    "& .MuiFab-primary": {
      marginLeft: 10
    }
  },
  deleteIconColour: {
    color: dangerColor[0]
  },
  ddArea: {
    backgroundColor: "rgba(0,0,0, 0.05)",
    border:"solid rgba(0,0,0, 0.1) 1px",
    borderRadius: 6,
    textAlign: "center",
    padding: 16,
    "&:hover": {
      backgroundColor: "rgba(0,0,0, 0.08)",
    }
  },
  blueText: {
    backgroundColor: primaryColour[900],
    color: whiteColor,
    padding: 10
  }
})

export default integrationStyle
