import React, { useContext, useEffect } from "react"

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles"
import { Grid, TextField, Button, Typography, Divider } from "@material-ui/core"

// Core components
import { useGlobalState } from "hooks/useGlobalState"
import update from 'immutability-helper';
// import classNames from 'classnames'

// Other components
import AdminIntegrationItemQty from "components/AdminIntegrationItem/AdminIntegrationItemQty"

// Style
import styles from "assets/jss/material-dashboard-react/layouts/integrationStyle"

const useStyles = makeStyles(styles)

const PresetsStepAdvanced = props => {
  const classes = useStyles();
  const dispatchState = useContext(useGlobalState.Dispatch)
  const state = useContext(useGlobalState.State)
  const { theRef } = props
  const cleanState = {
    ID: 0,
    preName: "",
    itemsAssigned:[],
    qtyAssigned: []
  }

  const saveNewPre = () => () => {
    // Save new
    dispatchState({
      type: 'integration-data',
      payload: {
        integrationSecPanel: false,
        integrationPresets: [...state.integrationData.integrationPresets, state.integrationData.integrationNewPreset]
      }
    })
    //Clean new state
    dispatchState({
      type: 'integration-data',
      payload: {
        integrationNewPreset: {...cleanState}
      }
    })
  }

  const savePre = () => () => {
    const i = state.integrationData.integrationEditing
    let arrayToChange = state.integrationData.integrationPresets
    //Save new
    dispatchState({
      type: 'integration-data',
      payload: {
        integrationSecPanel: false,
        integrationPresets: update(arrayToChange, {[i]: {$set: state.integrationData.integrationNewPreset}})
      }
    })
    //Clean new state
    dispatchState({
      type: 'integration-data',
      payload: {
        integrationNewUnit: {...cleanState}
      }
    })
  }

  /* eslint-disable */
  useEffect(() => {
    theRef.current.scrollTop = 0 //scrollTop back to 0 when changing views
  }, []);
  /* eslint-enable */
  
  return (
    <Grid spacing={3} container>
      <Grid item xs={12}>
        <Typography variant="h3" component="h2">{state.integrationData.integrationIsNewHelper ? "New preset": "Edit preset"}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider className={classes.customDividerOne} style={{marginBottom: 20}} />
        <TextField
          id="p-integration-preset-name"
          label="Preset name"
          variant="outlined"
          type="text"
          value={state.integrationData.integrationNewPreset.preName}
          onChange={(e)=>{dispatchState({ type: 'integration-data', payload: { integrationNewPreset:{...state.integrationData.integrationNewPreset, preName: e.target.value }} })}}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle1">Assign items to your preset</Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          {state.integrationData.itemLibrary.map((prop, index) => (
            <Grid item xs={4} key={index}>
              <AdminIntegrationItemQty
                prop={prop}
                index={index}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          className={classes.buttonSpacing}
          color="primary"
          size="large"
          fullWidth
          onClick={state.integrationData.integrationIsNewHelper ? saveNewPre() : savePre()}
        >Save{state.integrationData.integrationIsNewHelper ? " new " : " "}preset</Button>
      </Grid>
    </Grid>
  )
}

export default PresetsStepAdvanced
