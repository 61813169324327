import React from "react"

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles"
import { Typography, Grid, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, TablePagination, TableFooter } from "@material-ui/core"
import AccountCircleRounded from '@material-ui/icons/AccountCircleRounded'

// core components

// other components
import AdminHeading from "components/AdminHeading/AdminHeading"
import AdminPanels from "components/AdminPanels/AdminPanels"

// styles
import styles from "assets/jss/material-dashboard-react/layouts/adminStyle"
const useStyles = makeStyles(styles)

const MyAccount = props => {
  const classes = useStyles()
  return (
    <div>
      <AdminHeading
        overline="DASHBOARD"
        heading="My account"
        results={false}
        inMain={true}
        editable={false}
      />
      <Grid container spacing={4}>
        <Grid xs={12} md={12} lg={12} item>
          <AdminPanels
            theHeader='Account details'
            headerAction={() => {props.history.push('/admin/my-account/edit')}}
            fabCodeAction={false}
            fabCloneAction={false}
            fabEditAction={false}
            fabDeleteAction={false}
          >
            <Grid container spacing={3}>
              <Grid item xs={12} md={3} lg={3} className={classes.userBlock}>
                <AccountCircleRounded />
                <Typography variant="h6" component="p" className={classes.customH6}>Peter Citizen</Typography>
                <Typography variant="body2">hello@taxibox.com.au<br/>Change password</Typography>
              </Grid>
              <Grid item xs={12} md={9} lg={6}>
                <Typography variant="body2">
                  <strong>Phone:</strong> 0411111111<br />
                  <strong>Company name:</strong> TAXIBOX<br />
                  <strong>Company address:</strong> 123 Yellowbox rd, Melbourne VIC 3000</Typography>
              </Grid>
            </Grid>
          </AdminPanels>
        </Grid>
        <Grid xs={12} md={6} lg={6} item>
          <AdminPanels
            theHeader='My subscription'
            headerAction={() => {props.history.push('/admin/my-account/my-subscription')}}
            fabCodeAction={false}
            fabCloneAction={false}
            fabEditAction={false}
            fabDeleteAction={false}
          >
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12}>
                <Typography variant="h6" component="p" className={classes.customH6}>Starter 29/mo</Typography>
                <Typography variant="body2">1 Integration<br />1 - 5 facilities</Typography>
              </Grid>
            </Grid>
          </AdminPanels>
        </Grid>
        <Grid xs={12} md={6} lg={6} item>
          <AdminPanels
            theHeader='Payment method'
            headerAction={() => {props.history.push('/admin/my-account/payment-method')}}
            fabCodeAction={false}
            fabCloneAction={false}
            fabEditAction={false}
            fabDeleteAction={false}
          >
            {/* <img src={VisaIcon} /> */}
            <Typography variant="h6" component="p" className={classes.customH6}>VISA</Typography>
            <Typography variant="body2">xxxx xxxx xxxx x123<br /><strong>Renewal:</strong> 12/12/2020</Typography>
          </AdminPanels>
        </Grid>
        <Grid xs={12} md={12} lg={12} item>
          <AdminPanels
            theHeader='Billing history'
            headerAction={false}
            fabCodeAction={false}
            fabCloneAction={false}
            fabEditAction={false}
            fabDeleteAction={false}
          >
            <TableContainer component={Paper} className={classes.tableMarginTop}>
              <Table aria-label="Payment history">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Invoice</TableCell>
                    <TableCell align="center">Date</TableCell>
                    <TableCell align="center">Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key={0}>
                    <TableCell align="center" component="th" scope="row">01983784</TableCell>
                    <TableCell align="center">12/12/2019</TableCell>
                    <TableCell align="center">$49.99</TableCell>
                  </TableRow>
                  <TableRow key={1}>
                    <TableCell align="center" component="th" scope="row">01983784</TableCell>
                    <TableCell align="center">12/12/2019</TableCell>
                    <TableCell align="center">$49.99</TableCell>
                  </TableRow>
                  <TableRow key={2}>
                    <TableCell align="center" component="th" scope="row">01983784</TableCell>
                    <TableCell align="center">12/12/2019</TableCell>
                    <TableCell align="center">$49.99</TableCell>
                  </TableRow>
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination count={10} colSpan={3} labelRowsPerPage="Results per page" />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </AdminPanels>
        </Grid>
      </Grid>
    </div>
  );
}

export default MyAccount
