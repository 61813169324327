import React, { useContext, useEffect } from "react"

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles"
import { Grid, Typography, Divider } from "@material-ui/core"

// Core components
import { useGlobalState } from "hooks/useGlobalState"

// Other components
import Highlight from 'react-highlight.js';

// Style
import styles from "assets/jss/material-dashboard-react/layouts/integrationStyle"
import AdminPanels from "components/AdminPanels/AdminPanels";
import { Link } from "react-router-dom";

const useStyles = makeStyles(styles)

const ConfirmedStep = props => {
  const classes = useStyles();
  const dispatchState = useContext(useGlobalState.Dispatch)

  const { theRef } = props

  /* eslint-disable */
  useEffect(() => {
    dispatchState({ type: 'integration-data', payload: { integrationStepperStep: 6 }}) // Set stepper completed
    theRef.current.scrollTop = 0 //scrollTop back to 0 when changing views
  }, []);
  /* eslint-enable */

  const generatedCode = `<script type=“text/javascript”>
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag(‘js’, new Date());
      gtag(‘config’, ‘UA-17945334-2’);
    </script>`
  
  return (
    <form noValidate autoComplete="off" className={classes.standardForm}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5" component="h1">Confirmation</Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" component="h3">Integration has been published</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2">Ask your developer to copy and paste this code on the webpage where you want the calculator to be displayed.</Typography>
          <Highlight>{generatedCode}</Highlight>
        </Grid>
        <Grid item xs={12}>
          <AdminPanels
            theHeader={false}
            headerAction={false}
            fabCodeAction={false}
            fabCloneAction={false}
            fabEditAction={false}
            fabDeleteAction={false}
            panelTheme="dark"
          >
            <Typography variant="h5">Need assistance?</Typography>
            <Typography variant="body2">For further assistance, please <Link to="/admin/support">contact us</Link>. We’re here to help!</Typography>
          </AdminPanels>
        </Grid>
      </Grid>
      
    </form>
  )
}

export default ConfirmedStep
