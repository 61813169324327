import React, { useContext } from "react"

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles"
import { Grid, Typography, Stepper, Step, StepLabel } from "@material-ui/core"

// Core components

// Other components
import { useGlobalState } from "hooks/useGlobalState"
import AccountStep from "./Steps/AccountStep"
import PaymentStep from "./Steps/PaymentStep"
import ConfirmationStep from "./Steps/ConfirmationStep"

// Style
import styles from "assets/jss/material-dashboard-react/layouts/transactionalStyle"

const useStyles = makeStyles(styles)

const SignUp = props => {
  const classes = useStyles()
  const state = useContext(useGlobalState.State)

  return (
    <Grid container spacing={0} justify="center" className={classes.cFormContaner}>
      <Grid item xs={12} sm={12} md={12} className={classes.cWidthLimiter}>
        <Typography variant="h1" className={classes.headerOneLine}>{state.pages.signup.title}</Typography>
        <Stepper activeStep={state.signUpStepperStep} alternativeLabel style={{paddingTop:30, paddingBottom:0}}>
          {state.signUpStepperSteps.map(label => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {props.location.pathname === "/sign-up"  &&
          <AccountStep />
        }
        {props.location.pathname === "/sign-up/payment" &&
          <PaymentStep />
        }
        {props.location.pathname === "/sign-up/confirmation" &&
          <ConfirmationStep />
        }
      </Grid>
    </Grid>
  )
}

export default SignUp