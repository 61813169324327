import React from "react"

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles"
import { Grid, Typography } from "@material-ui/core"

// Core components

// Other components
import RecoveryForm from "./Steps/RecoveryForm"
import RecoveryRequested from "./Steps/RecoveryRequested"
import RecoveryReset from "./Steps/RecoveryReset"

// Style
import styles from "assets/jss/material-dashboard-react/layouts/transactionalStyle"
const useStyles = makeStyles(styles)

const PassRecovery = props => {
  const classes = useStyles()

  return (
    <Grid container spacing={0} justify="center" className={classes.cFormContaner}>
      <Grid item xs={12} sm={12} md={12} className={classes.cWidthLimiter}>
        <Typography variant="h1" className={classes.headerOneLine}>Password recovery</Typography>
        {props.location.pathname === "/password-request"  &&
          <RecoveryForm />
        }
        {props.location.pathname === "/password-request/requested" &&
          <RecoveryRequested />
        }
        {props.location.pathname === "/password-request/reset" &&
          <RecoveryReset />
        }
      </Grid>
    </Grid>
  );
}

export default PassRecovery
