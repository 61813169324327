import React, { useContext } from "react"
import classNames from "classnames"

// @material-ui/core components
import { Fab, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles"
import MenuItem from "@material-ui/core/MenuItem"
import MenuList from "@material-ui/core/MenuList"
import Grow from "@material-ui/core/Grow"
import Paper from "@material-ui/core/Paper"
import ClickAwayListener from "@material-ui/core/ClickAwayListener"
import Poppers from "@material-ui/core/Popper"
import Divider from "@material-ui/core/Divider"

// @material-ui/icons
import Person from "@material-ui/icons/PersonRounded"
import Notifications from "@material-ui/icons/NotificationsRounded"
import ContactSupport from "@material-ui/icons/ContactSupportRounded"

// core components
import { useGlobalState } from "hooks/useGlobalState"
import { Link } from "react-router-dom";

// styles
import styles from "assets/jss/material-dashboard-react/components/headerLinksStyle"



const useStyles = makeStyles(styles);

const AdminNavbarLinks = () => {
  const classes = useStyles();
  const dispatchState = useContext(useGlobalState.Dispatch)
  const [openNotification, setOpenNotification] = React.useState(null);
  const [openProfile, setOpenProfile] = React.useState(null);
  const handleClickNotification = event => {
    if (openNotification && openNotification.contains(event.target)) {
      setOpenNotification(null);
    } else {
      setOpenNotification(event.currentTarget);
    }
  };
  const handleCloseNotification = () => {
    setOpenNotification(null);
  };
  const handleClickProfile = event => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };
  const handleCloseProfile = () => {
    setOpenProfile(null);
  };
  return (
    <Grid
      container
      direction="row"
      justify="flex-end"
      alignItems="center"
    >
      <Grid item>
        <Fab
          color="primary"
          aria-label="Help"
          aria-haspopup="true"
          size="medium"
          className={classes.buttonLink}
          component={Link}
          to="/admin/support"
        >
          <ContactSupport className={classes.icons} />
          {/* <Hidden mdUp implementation="css">
            <p className={classes.linkText}>Marcelo</p>
          </Hidden> */}
        </Fab>
      </Grid>
      <Grid item>
        <Fab
          color="primary"
          aria-label="Notifications"
          aria-haspopup="true"
          size="medium"
          onClick={handleClickNotification}
          className={classes.buttonLink}
        >
          <Notifications className={classes.icons} />
          <span className={classes.notifications}>5</span>
          {/* <Hidden mdDown implementation="css">
            <p onClick={handleCloseNotification} className={classes.linkText}>
              Notifications
            </p>
          </Hidden> */}
        </Fab>
        <Poppers
          open={Boolean(openNotification)}
          anchorEl={openNotification}
          transition
          disablePortal
          className={
            classNames({ [classes.popperClose]: !openNotification }) +
            " " +
            classes.popperNav
          }
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="notification-menu-list-grow"
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom"
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleCloseNotification}>
                  <MenuList role="menu">
                    <MenuItem
                      onClick={handleCloseNotification}
                      className={classes.dropdownItem}
                    >
                      Welcome to Calcumate!
                    </MenuItem>
                    <MenuItem
                      onClick={handleCloseNotification}
                      className={classes.dropdownItem}
                    >
                      We've enabled to process your payment
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Poppers>
      </Grid>
      <Grid item>
        <Fab
          color="primary"
          aria-label="User profile"
          aria-haspopup="true"
          size="medium"
          onClick={handleClickProfile}
          className={classes.buttonLink}
        >
          <Person className={classes.icons} />
          {/* <Hidden mdUp implementation="css">
            <p className={classes.linkText}>Marcelo</p>
          </Hidden> */}
        </Fab>
        <Poppers
          open={Boolean(openProfile)}
          anchorEl={openProfile}
          transition
          disablePortal
          className={
            classNames({ [classes.popperClose]: !openProfile }) +
            " " +
            classes.popperNav
          }
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="profile-menu-list-grow"
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom"
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleCloseProfile}>
                  <MenuList role="menu">
                    <MenuItem
                      onClick={handleCloseProfile}
                      className={classes.dropdownItem}
                      component={Link}
                      to="/admin/my-account"
                    >
                      My account
                    </MenuItem>
                    <Divider light />
                    <MenuItem
                      onClick={()=>{dispatchState({ type: 'root-state', payload: { isLoggedIn: false } })}}
                      className={classes.dropdownItem}
                      component={Link}
                      to="/"
                    >
                      Logout
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Poppers>
      </Grid>
    </Grid>
  );
}

export default AdminNavbarLinks
