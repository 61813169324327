import React, { createContext, useReducer } from 'react'
import InitState from "variables/InitState"

// Context
const State = createContext()
const Dispatch = createContext()

// Reducer
const reducer = (state, action) => {
  switch (action.type) {
    case 'root-state':
      return {
        ...state,
        ...action.payload
      }
    case 'integration-data':
      return {
        ...state,
        integrationData: { ...state.integrationData, ...action.payload }
      }
    default:
      return state
  }
}

// Provider
const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, InitState)

  return (
    <State.Provider value={state}>
      <Dispatch.Provider value={dispatch}>{children}</Dispatch.Provider>
    </State.Provider>
  )
}

// Export
export const useGlobalState = {
  State,
  Dispatch,
  Provider,
}
