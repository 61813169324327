import React from "react"
import HomePage from "views/HomePage/HomePage"

const Home = () => {

  return (
      <HomePage />
  );
  
}

export default Home
