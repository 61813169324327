import React, { useContext, useEffect, useState } from "react"

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles"
import { Grid, Button, Typography, Divider, TableContainer, Table, TableRow, TableCell, TableBody, Fab, Tooltip, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@material-ui/core"
import EditRounded from '@material-ui/icons/EditRounded'
import DeleteForeverRounded from '@material-ui/icons/DeleteForeverRounded'

// Core components
import { useGlobalState } from "hooks/useGlobalState"
import update from 'immutability-helper';

// Other components

// Style
import styles from "assets/jss/material-dashboard-react/layouts/integrationStyle"

const useStyles = makeStyles(styles)

const UnitsStep = props => {
  const classes = useStyles();
  const dispatchState = useContext(useGlobalState.Dispatch)
  const state = useContext(useGlobalState.State)
  const [openDialog, setOpenDialog] = useState(false) //local state

  const { theRef } = props
  const cleanState = {
    ID: 0,
    unitName: "",
    unitWidth: "",
    unitHeight: "",
    unitDepth: ""
  }

  const editUnit = ID => () => {
    dispatchState({
      type: 'integration-data',
      payload: {
        integrationIsNewHelper: false,
        integrationSecPanel: true,
        integrationEditing: ID,
        integrationNewUnit: {...state.integrationData.integrationUnits[ID]}
      }
    })
  }

  const createUnit = () => () => {
    dispatchState({
      type: 'integration-data',
      payload: {
        integrationSecPanel: true,
        integrationIsNewHelper: true,
        integrationNewUnit: {...cleanState, ID:state.integrationData.integrationUnits.length}
      }
    })
  }

  const deleteUnit = ID => () => {
    setOpenDialog(false) //Close modal
    let arrayToChange = state.integrationData.integrationUnits
    //Save new
    dispatchState({
      type: 'integration-data',
      payload: {
        integrationSecPanel: false,
        integrationUnits: update(arrayToChange, {$splice: [[ID, 1]]})
      }
    })
    //Clean new state
    dispatchState({
      type: 'integration-data',
      payload: {
        integrationNewUnit: {...cleanState}
      }
    })
  }

  /* eslint-disable */
  useEffect(() => {
    dispatchState({ type: 'integration-data', payload: { integrationStepperStep: 1 }}) // Set stepper step number 2
    theRef.current.scrollTop = 0 //scrollTop back to 0 when changing views
  }, []);
  /* eslint-enable */
  
  return (
    <form noValidate autoComplete="off" className={classes.standardForm}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5" component="h1">Units</Typography>
        </Grid>
        {/* <Grid item xs={6} style={{textAlign:"right"}}>
          <Typography variant="overline" style={{textTransform:"initial"}}>{state.integrationData.integrationUnits.length} of 5 units</Typography>
        </Grid> */}
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <TableContainer>
            <Table className={classes.integrationTable} aria-label="Unit setup">
              <TableBody>
                {state.integrationData.integrationUnits.map((prop, index) => (
                  <TableRow key={index}>
                    <TableCell align="left">
                      <Typography variant="subtitle2" component="p">ID:{prop.ID}</Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography variant="subtitle2" component="p">{prop.unitName}</Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Tooltip title="Edit unit" aria-label="Edit unit">
                        <Fab
                          color="primary"
                          aria-label="Edit unit"
                          size="small"
                          onClick={editUnit(index)}
                        >
                          <EditRounded />
                        </Fab>
                      </Tooltip>
                      <Tooltip title="Delete unit" aria-label="Delete unit">
                        <Fab
                          color="primary"
                          aria-label="Delete unit"
                          size="small" 
                          onClick={()=>{setOpenDialog(true)}}
                          className={classes.deleteIconColour}
                        >
                          <DeleteForeverRounded />
                        </Fab>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            className={classes.buttonSpacing}
            color="secondary"
            size="medium"
            fullWidth
            onClick={createUnit()}
          >Add unit</Button>
        </Grid>
      </Grid>
      {/* Closing dialog */}
      <Dialog
        open={openDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Are you sure you want to delete?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Unit will be deleted
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button size="large" onClick={()=>{setOpenDialog(false)}} color="primary">No</Button>
          <Button size="large" onClick={deleteUnit()}color="primary">Yes</Button>
        </DialogActions>
      </Dialog>
    </form>
  )
}

export default UnitsStep
