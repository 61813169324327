import React, { useContext, useState } from "react"

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import { Typography, Card, Dialog, Button, DialogContent, DialogContentText, DialogActions, Fab, TextField, Tooltip, Grid } from "@material-ui/core"
import AddRounded from '@material-ui/icons/AddRounded'
import RemoveRounded from '@material-ui/icons/RemoveRounded'
import CheckCircleOutlineRounded from '@material-ui/icons/CheckCircleOutlineRounded'
import EditRounded from '@material-ui/icons/EditRounded'
import CloseRounded from '@material-ui/icons/CloseRounded'

// core components
import { useGlobalState } from "hooks/useGlobalState"
import classNames from "classnames"
import update from 'immutability-helper';

// other components

// styles
import styles from "assets/jss/material-dashboard-react/components/adminIntegrationItemStyle"
import { whiteColor } from "assets/jss/material-dashboard-react"

const useStyles = makeStyles(styles)

const AdminIntegrationItemQty = props => {
  const classes = useStyles()
  const dispatchState = useContext(useGlobalState.Dispatch)
  const state = useContext(useGlobalState.State)
  const { prop, index } = props
  const [openDialog, setOpenDialog] = useState(false) //local state
  const [customName, setCustomName] = useState("") //local state
  const qty = state.integrationData.integrationNewPreset.qtyAssigned[state.integrationData.integrationNewPreset.itemsAssigned.indexOf(prop.ID)]

  const checkActiveItem = () => {
    return state.integrationData.integrationNewPreset.itemsAssigned.includes(prop.ID)
  }

  const setToSelected = action => () => {
    let arrayToChange = state.integrationData.integrationNewPreset
    let position = arrayToChange.itemsAssigned.indexOf(prop.ID)
    if(checkActiveItem()){ //check if item is already there
      if(action === "add") { //if it's add
        dispatchState({
          type: 'integration-data',
          payload: {
            integrationNewPreset: update(arrayToChange, {qtyAssigned:{[position]:{$set: qty+1}}})
          }
        })
      }else{ //if it's remove
        if(qty > 1) {
          dispatchState({
            type: 'integration-data',
            payload: {
              integrationNewPreset: update(arrayToChange, {qtyAssigned:{[position]:{$set: qty-1}}})
            }
          })
        }else if(qty === 1){
          dispatchState({
            type: 'integration-data',
            payload: {
              integrationNewPreset: update(arrayToChange, {qtyAssigned:{$splice: [[position, 1]]}, itemsAssigned:{$splice: [[position, 1]]}})
            }
          })
        }
      }
    }else{
      if(action === "add") { //if it's add
        dispatchState({
          type: 'integration-data',
          payload: {
            integrationNewPreset: update(arrayToChange, {qtyAssigned: {$push: [1]}, itemsAssigned:{$push: [prop.ID]}})
          }
        })
      }
    }
  }

  const itemName = () => {
    if(prop.customName){
      return prop.customName
    }else{
      switch(state.integrationData.integrationActiveLanguage) {
        case "de":
          return prop.de
        default:
          return prop.defaultName
      }
    }
  }

  const saveCustomItemName = () => () => {
    if(customName){
      dispatchState({
        type: 'integration-data',
        payload: {
          itemLibrary: update(state.integrationData.itemLibrary, {[index]:{customName:{$set: [customName]}}})}
      })
    }else{
      dispatchState({
        type: 'integration-data',
        payload: {
          itemLibrary: update(state.integrationData.itemLibrary, {[index]:{customName:{$set: ""}}})}
      })
    }
    setOpenDialog(false)
  }

  return (
    <Card className={classNames(classes.itemCard, {[classes.cardActive]: checkActiveItem()})}>
      <Tooltip title="Customise item name" aria-label="Customise item name">
        <EditRounded onClick={()=>{setOpenDialog(true)}} className={classes.cardEdit} />
      </Tooltip>
      <div className={classes.itemCardAction}>
        {checkActiveItem() &&
          <CheckCircleOutlineRounded className={classes.itemCheckedIcon} />
        }
        <img src={prop.itemThumbnail} className={classes.itemCardImg} alt="" role="presentation" />
        <Typography variant="subtitle2" align="center">
          {itemName()}
        </Typography>
        <Typography variant="subtitle2" align="center">
          {state.integrationData.integrationMeasurement === "m" ? prop.dimention[0] : prop.dimention[1]} sq{state.integrationData.integrationMeasurement}
        </Typography>
        <Typography variant="caption" align="center">
          (H {state.integrationData.integrationMeasurement === "m" ? prop.h[0] : prop.h[1]}{state.integrationData.integrationMeasurement} W {state.integrationData.integrationMeasurement === "m" ? prop.w[0] : prop.w[1]}{state.integrationData.integrationMeasurement} D {state.integrationData.integrationMeasurement === "m" ? prop.d[0] : prop.d[1]}{state.integrationData.integrationMeasurement})
        </Typography>
      </div>
      <div className={classes.qtyControl}>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item>
            <RemoveRounded className={classes.controlBtn} onClick={setToSelected("remove")} />
          </Grid>
          <Grid item>
          <Typography variant="subtitle1" style={{color: whiteColor}}>{qty ? qty : 0}</Typography>
          </Grid>
          <Grid item>
            <AddRounded className={classes.controlBtn} onClick={setToSelected("add")} />
          </Grid>
        </Grid>
      </div>
      {/* Dialog */}
      <Dialog
        open={openDialog}
        className={classes.customDialog}
      >
        <Fab
            color="primary"
            aria-label="Edit"
            size="small"
            className={classes.closeFab}
            onClick={()=>{setOpenDialog(false)}}
          >
            <CloseRounded />
        </Fab>
        <DialogContent className={classes.customDialogContent}>
          <img src={prop.itemThumbnail} className={classes.customDialogImage} alt="" role="presentation" />
          <DialogContentText id="alert-dialog-description">
            <TextField
              id="p-integration-item-new-name"
              label="Custom item name"
              variant="outlined"
              type="text"
              helperText="Empty field will reset to default name"
              value={customName}
              onChange={(e)=>{setCustomName(e.target.value)}}
              fullWidth
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={saveCustomItemName()} size="medium" variant="contained" color="primary" fullWidth>Save item name</Button>
        </DialogActions>
      </Dialog>
    </Card>
  )
}

export default AdminIntegrationItemQty
