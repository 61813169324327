import React, { Fragment } from "react"

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles"
import { Typography, Button, Grid } from "@material-ui/core"
import AddRounded from '@material-ui/icons/AddRounded'

// core components
import { Link } from "react-router-dom"

// other components
import AdminHeading from "components/AdminHeading/AdminHeading"
import AdminPanels from "components/AdminPanels/AdminPanels"

// styles
import styles from "assets/jss/material-dashboard-react/layouts/adminStyle"

const useStyles = makeStyles(styles)

const MyCalcumate = () => {
  const classes = useStyles()
  return (
    <Fragment>
      <AdminHeading
        overline="DASHBOARD"
        heading="My calcumate"
        results="1 of 1 Integration"
        inMain={true}
        editable={false}
      />
      <Grid container spacing={4}>
        <Grid xm={12} md={12} lg={12} item>
          <AdminPanels
            theHeader={false}
            headerAction={false}
            fabCodeAction={() => {alert('Code button')}}
            fabCloneAction={() => {alert('Clone button')}}
            fabEditAction={() => {alert('Edit button')}}
            fabDeleteAction={() => {alert('Delete button')}}
          >
            <Typography variant="h6">Calcumate.co</Typography>
            <Typography variant="caption">ID:1</Typography>
          </AdminPanels>
        </Grid>
        <Grid xm={12} md={12} lg={12} item>
          <AdminPanels
            theHeader={false}
            headerAction={false}
            fabCodeAction={() => {alert('Code button')}}
            fabCloneAction={() => {alert('Clone button')}}
            fabEditAction={() => {alert('Edit button')}}
            fabDeleteAction={() => {alert('Delete button')}}
          >
            <Typography variant="h6">taxibox.com.au</Typography>
            <Typography variant="caption">ID:2</Typography>
          </AdminPanels>
        </Grid>
        <Grid xm={12} md={12} lg={12} item align="right">
          <Button component={Link} to="/admin/my-calcumate/new" className={classes.addNewIntegration} variant="contained" color="secondary" size="large" startIcon={<AddRounded />}>Add new</Button>
        </Grid>
      </Grid>
      <div style={{position: "fixed", bottom: 40, width:"calc(100% - 515px)"}}>
        <AdminPanels
          theHeader={false}
          headerAction={false}
          fabCodeAction={false}
          fabCloneAction={false}
          fabEditAction={false}
          fabDeleteAction={false}
          panelTheme="dark"
        >
          <Typography variant="h5">Need assistance?</Typography>
          <Typography variant="body1">Please <Link to="/admin/support">contact us</Link> if you have display issues and your developer can’t fix it quickly. We’ll help to fix it (usually free of charge).</Typography>
        </AdminPanels>
      </div>
    </Fragment>
  );
}

export default MyCalcumate
