import { primaryColour } from "assets/jss/material-dashboard-react"
import { dangerColor } from "assets/jss/material-dashboard-react"

const adminPanelsStyle = {
  customCard: {
    borderRadius: 15,
    boxShadow: `inset 8px 0 0 ${primaryColour[100]}`,
    paddingLeft: 38,
    paddingTop: 20,
    paddingBottom: 20,
    paddingRight: 30,
    "& h6": {
      lineHeight: 1,
      textTransform: "initial"
    },
    "& .MuiCardContent-root": {
      padding: 0
    },
    "& .MuiButtonBase-root": {
      marginLeft: 10
    }
  },
  panelHeader: {
    "&::after": {
      content: `" "`,
      display: "block",
      width: 30,
      height: 2,
      backgroundColor: primaryColour[400],
      marginTop: 10,
      marginBottom: 10
    }
  },
  deleteIconColour: {
    color: dangerColor[0]
  },
  customCardDark: {
    backgroundColor:primaryColour[100],
    boxShadow: "inset 8px 0 0 " + primaryColour[800]
  }
}

export default adminPanelsStyle
