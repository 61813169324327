import {
  drawerWidth,
  transition,
  container,
  primaryColour
} from "assets/jss/material-dashboard-react.js"

const adminStyle = theme => ({
  wrapper: {
    position: "relative",
    top: "0",
    height: "100vh"
  },
  mainPanel: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`
    },
    overflow: "auto",
    position: "relative",
    float: "right",
    ...transition,
    maxHeight: "100%",
    width: "100%",
    overflowScrolling: "touch"
  },
  content: {
    marginTop: "70px",
    padding: "30px 15px",
    minHeight: "calc(100vh - 123px)",
    [theme.breakpoints.up("md")]: {
      paddingTop: 0,
      paddingBottom: 30,
      paddingLeft: "6%",
      paddingRight: "6%"
    },
    [theme.breakpoints.up("lg")]: {
      paddingTop: 0,
      paddingBottom: 30,
      paddingLeft: "8%",
      paddingRight: "8%"
    },
    [theme.breakpoints.up("xl")]: {
      paddingTop: 0,
      paddingBottom: 30,
      paddingLeft: "10%",
      paddingRight: "10%"
    },
  },
  container,
  map: {
    marginTop: 70
  },
  addNewIntegration: {
    marginTop: 10,
    marginBottom: 10
  },
  tableMarginTop: {
    marginTop: 10,
    marginBottom: 10
  },
  userBlock: {
    position: "relative",
    "& .MuiTypography-root" :{
      paddingLeft: 80,
    },
    "& .MuiSvgIcon-root": {
      position: "absolute",
      color: primaryColour[800],
      width: 70,
      height: 70
    }
  },
  paymentBlock: {
    position: "relative",
    "& img": {
      position: "absolute",
      width: 50,
      height: 50,
    },
    "& .MuiTypography-body2" :{
      paddingLeft: 60,
    },
  },
  customH6: {
    color: primaryColour[800],
    fontSize:"1rem"
  }
})

export default adminStyle
