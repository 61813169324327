import React from "react";
// core components
import { Grid, Typography } from "@material-ui/core";

export default function NotFound() {
  return (
    <Grid container spacing={5} direction="row" justify="center" alignItems="center">
      <Grid item xs={12} sm={12}>
        <Typography variant="h1" align="center">404! Sorry</Typography>
      </Grid>
    </Grid>
  );
}