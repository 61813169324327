import React, { useContext, useEffect } from "react"

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles"
import { Grid, Button, TextField, Paper, Typography, List, ListItem, ListItemText } from "@material-ui/core"

// Core components

// Other components
import CustomPricingWidget from "components/CustomPricing/CustomPricingWidget"
import { useGlobalState } from "hooks/useGlobalState"

// Style
import styles from "assets/jss/material-dashboard-react/layouts/transactionalStyle"
import { Link } from "react-router-dom"
import { primaryColour } from "assets/jss/material-dashboard-react"

const useStyles = makeStyles(styles)

const AccountStep = () => {
  const classes = useStyles();
  const dispatchState = useContext(useGlobalState.Dispatch)
  /* eslint-disable */
  useEffect(() => {
    dispatchState({ type: 'root-state', payload: { signUpStepperStep: 2 }}) // Set stepper step number 3
  }, []);
  /* eslint-enable */

  return (
    <Grid container spacing={3} className={classes.standardForm}>
      <Grid item xs={12}>
        <CustomPricingWidget />
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <Paper variant="outlined" className={classes.dataPanel}>
          <Typography variant="h5" component="h2">Account details</Typography>
          <List dense>
            <ListItem disableGutters>
              <ListItemText variant="caption"><strong>First name:</strong> Peter Citizen</ListItemText>
            </ListItem>
            <ListItem disableGutters>
              <ListItemText variant="caption"><strong>Last name:</strong> Citizen</ListItemText>
            </ListItem>
            <ListItem disableGutters>
              <ListItemText variant="caption"><strong>Email:</strong> hello@taxibox.com.au</ListItemText>
            </ListItem>
            <ListItem disableGutters>
              <ListItemText variant="caption"><strong>Phone:</strong> 0411111111</ListItemText>
            </ListItem>
            <ListItem disableGutters>
              <ListItemText variant="caption"><strong>Company name:</strong> TAXIBOX</ListItemText>
            </ListItem>
          </List>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <Paper variant="outlined" elevation={3} className={classes.dataPanel}>
          <Typography variant="h5" component="h2">Payment details</Typography>
          <List dense>
            <ListItem disableGutters>
              <ListItemText variant="caption"><strong>Credit card:</strong> xxxx xxxx xxxx x234</ListItemText>
            </ListItem>
            <ListItem disableGutters>
              <ListItemText variant="caption"><strong>Country:</strong> Australia</ListItemText>
            </ListItem>
            <ListItem disableGutters>
              <ListItemText variant="caption"><strong>Name:</strong> TAXIBOX</ListItemText>
            </ListItem>
            <ListItem disableGutters>
              <ListItemText variant="caption"><strong>Address:</strong> 123 Test rd, Testfield</ListItemText>
            </ListItem>
            <ListItem disableGutters>
              <ListItemText variant="caption"><strong>Business ID:</strong> 123 567 890 234</ListItemText>
            </ListItem>
          </List>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper variant="outlined" className={classes.dataPanel}>
          <Typography variant="subtitle1" align="center" style={{color:primaryColour[900]}}>We’ve sent you a four digit confirmation code to hello@taxibox.com.au</Typography>
          <Grid container spacing={1} direction="row" justify="center" alignItems="center">
            <Grid item><TextField id="p-signup-code1" type="text" maxLength="1" className={classes.validationCodeInput}/></Grid>
            <Grid item><TextField id="p-signup-code2" type="text" maxLength="1" className={classes.validationCodeInput}/></Grid>
            <Grid item><TextField id="p-signup-code3" type="text" maxLength="1" className={classes.validationCodeInput}/></Grid>
            <Grid item><TextField id="p-signup-code4" type="text" maxLength="1" className={classes.validationCodeInput}/></Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle1">By signing up, you agree to Calcumate’s <Link to="/terms-of-service">Terms and Conditions of Service.</Link></Typography>
      </Grid>
      <Grid item xs={6}>
        <Button
          component={Link}
          to="/sign-up/payment"
          variant="outlined"
          className={classes.buttonSpacing}
          color="primary"
          size="large"
          fullWidth
        >
          Back
        </Button>
      </Grid>
      <Grid item xs={6}>
        <Button
          variant="contained"
          className={classes.buttonSpacing}
          component={Link}
          to="/admin"
          color="primary"
          size="large"
          onClick={()=>{dispatchState({ type: 'root-state', payload: { isLoggedIn: true } })}}
          fullWidth
        >
          Create account
        </Button>
      </Grid>
    </Grid>
  );
}

export default AccountStep;
