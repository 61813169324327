import React, { useContext, useEffect, createRef } from "react"

// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar"
import "perfect-scrollbar/css/perfect-scrollbar.css"

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"

// core components
import { Switch, Redirect } from "react-router-dom"
import { useGlobalState } from "hooks/useGlobalState"

// other components
import Navbar from "components/Navbars/Navbar"
import AdminSidebar from "components/AdminSidebar/AdminSidebar"
import routes from "routes"

// styles
import adminStyle from "assets/jss/material-dashboard-react/layouts/adminStyle"
import logo from "assets/img/calcumate-logo@2x.png"

let ps;
const useStyles = makeStyles(adminStyle);

const Admin = props => {
  const classes = useStyles();
  const mainPanel = createRef(); // ref to help us initialize PerfectScrollbar on windows devices
  let state = useContext(useGlobalState.State)
  const dispatchState = useContext(useGlobalState.Dispatch)
  const { children } = props;

  const handleDrawerToggle = () => {
    dispatchState({ type: 'root-state', payload: { adminNavigationMobile: !state.adminNavigationMobile } })
  };

  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      dispatchState({ type: 'root-state', payload: { adminNavigationMobile: false } })
    }
  };

  // initialize and destroy the PerfectScrollbar plugin
  /* eslint-disable */
  useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  }, []);
  /* eslint-enable */

  return (
    <div className={classes.wrapper}>
      <AdminSidebar
        routes={routes}
        logo={logo}
        handleDrawerToggle={handleDrawerToggle}
        open={state.adminNavigationMobile}
      />
      <div className={classes.mainPanel} ref={mainPanel}>
        <Navbar
          routes={routes}
          handleDrawerToggle={handleDrawerToggle}
        />
        <div className={classes.content}>
          <div className={classes.container}>
            <Switch>
              <Redirect exact from="/admin" to="/admin/my-calcumate" />
            </Switch>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Admin
