const adminHeadingStyle = {
  results: {
    textTransform: "lowercase",
    letterSpacing: 1
  },
  headerMargin: {
    marginBottom: 20
  },
  extraMargin: {
    marginBottom: 30
  }
};

export default adminHeadingStyle;
