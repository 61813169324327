import { primaryColour } from "assets/jss/material-dashboard-react";

const muiFormStyle = {
  MuiFormLabel: {
    root: {
      fontFamily:'Roboto, sans-serif',
      "&.Mui-focused": {
        color: primaryColour[500],
      },
      "&.MuiInputLabel-formControl": {
        transform: "translate(14px, 20px) scale(1)",
      },
      "&.MuiInputLabel-shrink": {
        transform: "translate(14px, -5px) scale(0.75)",
      },
    },
  },
  MuiOutlinedInput: {
      root: {
        fontFamily:'Roboto, sans-serif',
        fontWeight: 400,
        marginBottom: 5,
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: primaryColour[500],
        },
      },
  },
  MuiFormHelperText: {
    root: {
      fontFamily:'Roboto, sans-serif',
      fontSize: 12,
      marginTop: 0
    }
  }
};

export default muiFormStyle;
