import React, { useContext, useState } from "react"

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import { Typography, Card, CardActionArea, Dialog, Button, DialogContent, DialogContentText, DialogActions, Fab, TextField, Tooltip } from "@material-ui/core"
import RadioButtonUncheckedRounded from '@material-ui/icons/RadioButtonUncheckedRounded'
import CheckCircleOutlineRounded from '@material-ui/icons/CheckCircleOutlineRounded'
import EditRounded from '@material-ui/icons/EditRounded'
import CloseRounded from '@material-ui/icons/CloseRounded'

// core components
import { useGlobalState } from "hooks/useGlobalState"
import classNames from "classnames"
import update from 'immutability-helper';

// other components

// styles
import styles from "assets/jss/material-dashboard-react/components/adminIntegrationItemStyle"

const useStyles = makeStyles(styles)

const AdminIntegrationItem = props => {
  const classes = useStyles()
  const dispatchState = useContext(useGlobalState.Dispatch)
  const state = useContext(useGlobalState.State)
  const { prop, index } = props
  const [openDialog, setOpenDialog] = useState(false) //local state
  const [customName, setCustomName] = useState("") //local state

  const checkActiveItem = () => {
    return state.integrationData.integrationNewCategory.itemsAssigned.includes(prop.ID)
  }

  const setToSelected = () => () => {
    let arrayToChange = state.integrationData.integrationNewCategory
    if(checkActiveItem()){
      dispatchState({
        type: 'integration-data',
        payload: {
          integrationNewCategory: update(arrayToChange, {itemsAssigned:{$splice: [[arrayToChange.itemsAssigned.indexOf(index), 1]]}})
        }
      })
    }else{
      dispatchState({
        type: 'integration-data',
        payload: {
          integrationNewCategory: update(arrayToChange, {itemsAssigned:{$push: [index]}})
        }
      })
    }
  }

  const itemName = () => {
    if(prop.customName){
      return prop.customName
    }else{
      switch(state.integrationData.integrationActiveLanguage) {
        case "de":
          return prop.de
        default:
          return prop.defaultName
      }
    }
  }

  const saveCustomItemName = () => () => {
    if(customName){
      dispatchState({
        type: 'integration-data',
        payload: {
          itemLibrary: update(state.integrationData.itemLibrary, {[index]:{customName:{$set: [customName]}}})}
      })
    }else{
      dispatchState({
        type: 'integration-data',
        payload: {
          itemLibrary: update(state.integrationData.itemLibrary, {[index]:{customName:{$set: ""}}})}
      })
    }
    setOpenDialog(false)
  }

  return (
    <Card className={classNames(classes.itemCard, {[classes.cardActive]: checkActiveItem()})}>
      <Tooltip title="Customise item name" aria-label="Customise item name">
        <EditRounded onClick={()=>{setOpenDialog(true)}} className={classes.cardEdit} />
      </Tooltip>
      <CardActionArea onClick={setToSelected()} className={classes.itemCardAction}>
        {checkActiveItem() ? <CheckCircleOutlineRounded className={classes.itemCheckedIcon} /> : <RadioButtonUncheckedRounded className={classes.itemCheckedIcon} />}
        <img src={prop.itemThumbnail} className={classes.itemCardImg} alt="" role="presentation" />
        <Typography variant="subtitle2" align="center">
          {itemName()}
        </Typography>
        <Typography variant="subtitle2" align="center">
          {state.integrationData.integrationMeasurement === "m" ? prop.dimention[0] : prop.dimention[1]} sq{state.integrationData.integrationMeasurement}
        </Typography>
        <Typography variant="caption" align="center">
          (H {state.integrationData.integrationMeasurement === "m" ? prop.h[0] : prop.h[1]}{state.integrationData.integrationMeasurement} W {state.integrationData.integrationMeasurement === "m" ? prop.w[0] : prop.w[1]}{state.integrationData.integrationMeasurement} D {state.integrationData.integrationMeasurement === "m" ? prop.d[0] : prop.d[1]}{state.integrationData.integrationMeasurement})
        </Typography>
      </CardActionArea>

      {/* Dialog */}
      <Dialog
        open={openDialog}
        className={classes.customDialog}
      >
        <Fab
            color="primary"
            aria-label="Edit"
            size="small"
            className={classes.closeFab}
            onClick={()=>{setOpenDialog(false)}}
          >
            <CloseRounded />
        </Fab>
        <DialogContent className={classes.customDialogContent}>
          <img src={prop.itemThumbnail} className={classes.customDialogImage} alt="" role="presentation" />
          <DialogContentText id="alert-dialog-description">
            <TextField
              id="p-integration-item-new-name"
              label="Custom item name"
              variant="outlined"
              type="text"
              helperText="Empty field will reset to default name"
              value={customName}
              onChange={(e)=>{setCustomName(e.target.value)}}
              fullWidth
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={saveCustomItemName()} size="medium" variant="contained" color="primary" fullWidth>Save item name</Button>
        </DialogActions>
      </Dialog>
    </Card>
  )
}

export default AdminIntegrationItem
