import { primaryColour } from "assets/jss/material-dashboard-react"
import { whiteColor } from "assets/jss/material-dashboard-react"

const paymentSelectedSyle = {
  CustomPricingW: {
    borderColor: primaryColour[800],
    borderRadius: 15,
    color: primaryColour[900],
    backgroundColor: primaryColour[50]
  },
  planSelected: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 25,
    paddingRight: 25,
    "& .MuiFab-primary": {
      backgroundColor: whiteColor,
      verticalAlign: "top"
    },
    "& .MuiFab-primary:hover": {
      backgroundColor: primaryColour[100]
    }
  },
  cardDetails: {
    display:"inline-block",
    color: primaryColour[900],
    lineHeight: 1,
    marginLeft: 15
  }
};

export default paymentSelectedSyle;
