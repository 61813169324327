import {
  defaultFont,
  dangerColor,
  whiteColor
} from "assets/jss/material-dashboard-react.js";

const headerLinksStyle = theme => ({
  linkText: {
    zIndex: "4",
    ...defaultFont,
    fontSize: "14px",
    margin: "0px"
  },
  buttonLink: {
    backgroundColor: whiteColor,
    marginLeft: 10,
    marginRight: 10
  },
  margin: {
    zIndex: "4",
    margin: "0"
  },
  searchIcon: {
    width: "17px",
    zIndex: "4"
  },
  notifications: {
    zIndex: "4",
    position: "absolute",
    top: 0,
    border: "1px solid " + whiteColor,
    right: 0,
    fontSize: "12px",
    background: dangerColor[0],
    color: whiteColor,
    minWidth: "18px",
    height: "18px",
    borderRadius: "10px",
    textAlign: "center",
    lineHeight: "18px",
    verticalAlign: "middle",
    display: "block"
  }
})

export default headerLinksStyle
