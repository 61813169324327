import { primaryColour } from "assets/jss/material-dashboard-react";
import bgImg from "assets/img/hero-bg-texture.svg";
import boxImg from "assets/img/hero-bg@2x.png";
import elipseShine from "assets/img/shines.svg";
import { whiteColor } from "assets/jss/material-dashboard-react";

const homePageStyle = theme => ({
  heroBanner: {
    //  backgroundImage: "linear-gradient(30deg, " + primaryColour[900] + " 0%, " + primaryColour[700] + " 100%)",
    //  backgroundColor: primaryColour[900],
    background: "linear-gradient(220deg, #005695, #5E33A2, #00437C, #0098CC)",
    backgroundSize: "600% 600%",
    height: "100vh",
    position: "relative",
    animation: "backgroundAmination 20s linear infinite",
    
    "&::before": {
      content: `" "`,
      display: "block",
      position: "absolute",
      height: "50%",
      width: "100%",
      bottom: "0",
      backgroundImage: "url(" + bgImg + ")",
      backgroundSize: "3000px auto",
      backgroundPosition: "center top",
      backgroundRepeat: "no-repeat",
    },
  },
  mainSection: {
    maxWidth: 750,
    width: "100%",
    height: "100%",
    position: "relative",
    margin:"0 auto"
  },
  heroContent: {
    maxWidth: 992,
    width: "100%",
    height: "100%",
    position: "relative",
    margin: "0 auto",
    "& img": {
      width: "100%",
    },
    "&::before": {
      content: `" "`,
      display: "block",
      position: "absolute",
      left: "50%",
      height: "100%",
      width: "500px",
      backgroundImage: "url(" + boxImg + ")",
      backgroundSize: "500px auto",
      backgroundPosition: "center 50%",
      backgroundRepeat: "no-repeat",
      zIndex: 2
    },
    "&::after": {
      content: `" "`,
      display: "block",
      position: "absolute",
      right: "0",
      height: "100%",
      width: "60%",
      backgroundImage: "url(" + elipseShine + ")",
      backgroundSize: "100% auto",
      backgroundPosition: "center 40%",
      backgroundRepeat: "no-repeat",
      zIndex: 1,
      mixBlendMode: "overlay"
    },
  },
  mainHeader: {
    color: whiteColor,
    fontSize: "48px",
    marginBottom: theme.spacing(3)
  },
  mainSubHeading: {
    color: primaryColour[100],
    lineHeight: "130%",
    fontSize: 20,
    marginBottom: theme.spacing(2)
  },
  mb1: {
    marginBottom: 10
  },
  features: {
    marginBottom: 70
  },
  caseStudy: {
    paddingTop: 70,
    paddingBottom: 70,
    marginLeft: 15,
    marginRight: 15,
    marginBottom: 15,
    marginTop: 0,
    backgroundColor: whiteColor,
    borderRadius: 30
  },
  circleFeature: {
    backgroundColor:primaryColour[50],
    borderRadius: "50%",
    width: 163,
    height: 163,
    textAlign: "center"
  },
  featureMain: {
    fontSize: 48,
    fontWeight: 600,
    color: primaryColour[900],
    textAlign: "center",
    "& sup": {
      fontSize: 28
    }
  },
  featureSub: {
    textTransform: "initial",
    letterSpacing: 0,
    textAlign: "center",
    lineHeight: 1,
    color: primaryColour[500]
  },
  demo: {
    backgroundColor: primaryColour[800],
    borderRadius: 30,paddingTop: 70,
    paddingBottom: 0,
    marginLeft: 15,
    marginRight: 15
  },
  integration: {
    backgroundColor: primaryColour[100],
    borderRadius: 30,
    minHeight: 200,
    marginBottom: 15,
    textAlign: "center"
  },
  startFreeTxt: {
    textAlign: "center",
    color: primaryColour[900],
    marginBottom: 20
  },
  startForFree: {
    paddingTop: 70,
    paddingBottom: 70,
    marginLeft: 15,
    marginRight: 15,
    marginBottom: 15,
    marginTop: 0,
    backgroundColor: whiteColor,
    borderRadius: 30,
    textAlign: "center"
  }
})

export default homePageStyle
