import { whiteColor, primaryColour, secondaryColour } from "assets/jss/material-dashboard-react";

const muiButtonStyle = {
  MuiButton: {
    root: {
      borderRadius: 30,
      color: primaryColour[800],
      overflow: "hidden",
      fontSize: "1rem",
      lineHeight: 1.3,
      paddingTop: 11,
      paddingBottom: 11,
      paddingLeft: 21,
      paddingRight: 21
    },
    sizeSmall: {
      fontSize: "0.875rem",
      paddingTop: 9,
      paddingBottom: 9,
      paddingLeft: 19,
      paddingRight: 19
    },
    sizeLarge: {
      fontSize: "1rem",
      paddingTop: 14,
      paddingBottom: 14,
      paddingLeft: 26,
      paddingRight: 26
    },
    contained: {
      backgroundColor: whiteColor,
      color: primaryColour[800],
      boxShadow: 'none',
    },
    containedPrimary: {
      backgroundColor: primaryColour[800],
      color: whiteColor,
      boxShadow: "0 2px 0 rgba(0,0,0, 0.2)",
      "&:hover, &:focus &:active": {
        boxShadow: "0 2px 0 rgba(0,0,0, 0.2)",
      },
    },
    containedSecondary: {
      backgroundColor: secondaryColour[500],
      color: whiteColor,
      boxShadow: "0 2px 0 rgba(0,0,0, 0.2), inset 0 0 0 2px " + secondaryColour[500],
      "&:hover, &:focus &:active": {
        boxShadow: "0 2px 0 rgba(0,0,0, 0.2)",
      },
    },
    outlined: {
      border: 0,
      boxShadow: "inset 0 0 0 2px " + whiteColor,
      color: whiteColor,
      "&:hover, &:focus &:active": {
        border: 0,
        backgroundColor: whiteColor,
        color: primaryColour[900]
      }
    },
    outlinedPrimary: {
      border: 0,
      boxShadow: "inset 0 0 0 2px " + primaryColour[800],
      color: primaryColour[800],
      "&:hover, &:focus &:active": {
        border: 0,
        backgroundColor: primaryColour[800],
        color: whiteColor
      }
    },
    outlinedSecondary: {
      borderColor: secondaryColour[800],
      color: secondaryColour[800],
      borderWidth: 2,
      "&:hover, &:focus &:active": {
        border: 0,
        backgroundColor: secondaryColour[800],
        color: whiteColor
      }
    },
  },
};

export default muiButtonStyle;
