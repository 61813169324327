const muiBackdropStyle = {
  MuiBackdrop: {
    root: {
      backgroundColor: "rgba(0, 67, 124, 0.8)",
      "& .MuiDialog-paper": {
        borderRadius: 15
      }
    },
  },
};

export default muiBackdropStyle;
