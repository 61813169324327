/*eslint-disable*/
import React, { useState } from "react"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
// core components
import { TextField, InputAdornment } from "@material-ui/core"
import { ChromePicker } from "react-color"
import ColourPickerDialog from "./ColourPickerDialog"

const styles = {
  colourCircle: {
    position: "absolute",
    width: 8,
    height: 8,
    borderRadius: "50%"
  }
}

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  const [colorPicker, setColorPicker] = useState(false)
  const {value, onChange, label, id } = props
  return (
    <div className={classes.pickerWrapper}>
      <TextField
        value = {value}
        label={label}
        id={id}
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="end">
              <div style={{position:"absolute", right:15,  width:10, height:10, backgroundColor:value, borderRadius:"50%"}}></div>
            </InputAdornment>
          ),
        }}
        variant="outlined"
        onClick={()=>{
          setColorPicker(!colorPicker)
          document.body.classList.add("colorOpen")
        }}
      />
      {colorPicker &&
        <ColourPickerDialog
          value={value}
          onChange={onChange}
          onClick={()=>{
            setColorPicker(false)
            document.body.classList.remove("colorOpen")
          }}
        />
      }
    </div>
  )
}
