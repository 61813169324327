import React, { useContext, useEffect, useState } from "react"

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles"
import { Grid, TextField, Button, Typography, Divider, FormControl, InputLabel, Select, MenuItem, RadioGroup, FormControlLabel, Radio, Popover, Switch } from "@material-ui/core"
import HelpRounded from "@material-ui/icons/HelpRounded"

// Core components
import { useGlobalState } from "hooks/useGlobalState"

// Other components

// Style
import styles from "assets/jss/material-dashboard-react/layouts/integrationStyle"

const useStyles = makeStyles(styles)

const OptionStep = props => {
  const classes = useStyles();
  const dispatchState = useContext(useGlobalState.Dispatch)
  const state = useContext(useGlobalState.State)
  const { theRef } = props
  const [domainUrlPop, setDomainUrlPop] = useState(null)
  const [ctaUrlPop, setCtaUrlPop] = useState(null)

  //const open = Boolean(domainUrlPop);

  /* eslint-disable */
  useEffect(() => {
    dispatchState({ type: 'integration-data', payload: { integrationStepperStep: 0 }}) // Set stepper step number 1
    theRef.current.scrollTop = 0 //scrollTop back to 0 when changing views
  }, []);
  /* eslint-enable */
  
  return (
    <form noValidate autoComplete="off" className={classes.standardForm}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5" component="h1">Options</Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1" component="h2" className={classes.subHeadOne}>Domain <HelpRounded style={{verticalAlign:"sub", fontSize:"1.3rem"}} onClick={(event)=>{setDomainUrlPop(event.currentTarget)}}/></Typography>
          <Popover
            id="domainURL"
            open={Boolean(domainUrlPop)}
            anchorEl={domainUrlPop}
            onClose={()=>{setDomainUrlPop(null)}}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Typography variant="body2" className={classes.blueText}>This is the URL of the website where this integration will be loaded.</Typography>
          </Popover>
          <TextField
            id="p-integration-url"
            label="URL"
            variant="outlined"
            type="text"
            helperText="Example: https://yourwebsite.com"
            fullWidth
            defaultValue={state.integrationData.integrationURL}
            onChange={(e)=>{dispatchState({ type: 'integration-data', payload: { integrationURL: e.target.value } })}}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider className={classes.customDividerOne} />
          <Typography variant="subtitle1" component="h2" className={classes.subHeadOne}>Integration language</Typography>
          <FormControl fullWidth>
            <InputLabel htmlFor="p-integration-language">Select language</InputLabel>
            <Select
              id="p-integration-language"
              value={state.integrationData.integrationActiveLanguage}
              variant="outlined"
              label="Select language"
              onChange={(e)=>{dispatchState({ type: 'integration-data', payload: { integrationActiveLanguage: e.target.value } })}}
            >
              {state.integrationData.integrationLanguages.map((label, key) => (
                <MenuItem key={key} value={label.value}>{label.language}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Divider className={classes.customDividerOne} />
          <Typography variant="subtitle1" component="h2" className={classes.subHeadOne}>Select storage type</Typography>
          <FormControl component="fieldset">
            <RadioGroup row aria-label="storage-type" name="storage-type">
              <FormControlLabel
                value="self-storage"
                control={<Radio color="primary" />}
                label="Self storage"
                onChange={(e)=>{dispatchState({ type: 'integration-data', payload: { integrationStorageType: e.target.value } })}}
                labelPlacement="end"
                checked = {state.integrationData.integrationStorageType === "self-storage"}
                className={classes.customRadio}
              />
              <FormControlLabel
                value="mobile-storage"
                control={<Radio color="primary" />}
                label="Mobile storage"
                onChange={(e)=>{dispatchState({ type: 'integration-data', payload: { integrationStorageType: e.target.value } })}}
                labelPlacement="end"
                checked = {state.integrationData.integrationStorageType === "mobile-storage"}
                className={classes.customRadio}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Divider className={classes.customDividerOne} />
          <Typography variant="subtitle1" component="h2" className={classes.subHeadOne}>Select measurement system</Typography>
          <FormControl component="fieldset">
            <RadioGroup row aria-label="measurement-method" name="measurement-method">
              <FormControlLabel
                value="m"
                control={<Radio color="primary" />}
                label="Metric"
                onChange={(e)=>{dispatchState({ type: 'integration-data', payload: { integrationMeasurement: e.target.value } })}}
                labelPlacement="end"
                checked = {state.integrationData.integrationMeasurement === "m"}
                className={classes.customRadio}
              />
              <FormControlLabel
                value="ft"
                control={<Radio color="primary" />}
                label="Imperial"
                onChange={(e)=>{dispatchState({ type: 'integration-data', payload: { integrationMeasurement: e.target.value } })}}
                labelPlacement="end"
                checked = {state.integrationData.integrationMeasurement === "ft"}
                className={classes.customRadio}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Divider className={classes.customDividerOne} />
          <Typography variant="subtitle1" component="h2" className={classes.subHeadOne}>Call to action URL <HelpRounded style={{verticalAlign:"sub", fontSize:"1.3rem"}} onClick={(event)=>{setCtaUrlPop(event.currentTarget)}}/></Typography>
          <Popover
            id="ctaPopover"
            open={Boolean(ctaUrlPop)}
            anchorEl={ctaUrlPop}
            onClose={()=>{setCtaUrlPop(false)}}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Typography variant="body2" className={classes.blueText}>Link on the button displayed on the calculation result.</Typography>
          </Popover>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            spacing={1}
          >
            <Grid item>
              <Typography variant="subtitle2">Use phone number instead of a URL</Typography>
            </Grid>
            <Grid item>
              <Switch
                checked={state.integrationData.integrationCTAType}
                onChange={()=>{dispatchState({ type: 'integration-data', payload: { integrationCTAType: !state.integrationData.integrationCTAType } })}}
                color="primary"
                name="cta-type"
                inputProps={{ 'aria-label': 'CTA type' }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="p-integration-cta-url"
                label={state.integrationData.integrationCTAType ? "Phone number" : "URL"}
                variant="outlined"
                type="text"
                helperText={state.integrationData.integrationCTAType ? "Example: 1234 567 899" : "Example: https://yourwebsite.com/book-now"}
                onChange={(e)=>{dispatchState({ type: 'integration-data', payload: { integrationCtaURL: e.target.value } })}}
                value={state.integrationData.integrationCtaURL}
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            className={classes.buttonSpacing}
            color="secondary"
            size="medium"
            fullWidth
            onClick={(e)=>{dispatchState({ type: 'integration-data', payload: { integrationSecPanel: true } })}}
          >Show advanced settings</Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default OptionStep
