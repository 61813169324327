import React, { useContext } from "react";

// @material-ui/core
import { Typography, makeStyles, Fab, TextField } from "@material-ui/core"
import EditRounded from '@material-ui/icons/EditRounded'
import FullscreenRounded from '@material-ui/icons/FullscreenRounded'
import FullscreenExitRounded from '@material-ui/icons/FullscreenExitRounded'

// core components

// other components
import { useGlobalState } from "hooks/useGlobalState"

// styles
import styles from "assets/jss/material-dashboard-react/components/integrationHeadingStyle"
const useStyles = makeStyles(styles)

const IntegrationHeading = props => {
  const classes = useStyles()
  const dispatchState = useContext(useGlobalState.Dispatch)
  const state = useContext(useGlobalState.State)

  const editHeading = event => {
    dispatchState({ type: "integration-data", payload: { integrationName: event.target.value } })
  }

  const setFullScreen = () => {
    dispatchState({ type: "integration-data", payload: { integrationPanelsFS: !state.integrationData.integrationPanelsFS } })
  }

  return (
    <div className={classes.inHeadingWrapper}>
      <Typography variant="overline">New integration</Typography>
      <div className={classes.inputWrapper}>
        <TextField
          id="integration-name"
          aria-describedby="integration-name"
          onChange={editHeading}
          defaultValue={state.integrationData.integrationName}
          className={classes.modInput}
          inputProps={{ maxLength: 25 }}
          autoFocus
        />
        <Fab
          color="primary"
          aria-label="Edit"
          size="small"
          className={classes.inputFab}
        >
          <EditRounded />
        </Fab>
      </div>
      <div className={classes.panelOneController}>
        <Fab
          color="primary"
          aria-label="Hide panels"
          size="large"
          onClick={setFullScreen}
        >
          {!state.integrationData.integrationPanelsFS &&
            <FullscreenRounded />
          }
          {state.integrationData.integrationPanelsFS &&
            <FullscreenExitRounded />
          }
        </Fab>
      </div>
    </div>
  );
}

export default IntegrationHeading
