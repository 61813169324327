import React from "react"

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"

import { Grid, Typography, Button } from "@material-ui/core"

// Core components
import { Link } from "react-router-dom";
import classNames from "classnames"

//Other components
import Footer from "components/Footer/Footer"
import HomeHeader from "components/HomeHeader/HomeHeader"
import SimpleHeading from "components/SimpleHeading/SimpleHeading"

//Styles
import styles from "assets/jss/material-dashboard-react/views/homePageStyle"
import nextLevel from "assets/img/icon-next-level.svg"
import fullyCustom from "assets/img/icon-fully-customisable.svg"
import simpleSolution from "assets/img/icon-simple-solution.svg"
import easyIntegration from "assets/img/icon-easy-integration.svg"
import unbeatablePricing from "assets/img/icon-unbeatable-pricing.svg"
import tbCaseStudy from "assets/img/taxibox-case-study@2x.png"

const useStyles = makeStyles(styles)

const HomePage = () =>  {
  const classes = useStyles()
  return (
    <div>
      <HomeHeader />
      <section className={classes.heroBanner} id="main-section">
        <Grid container spacing={5} className={ classNames(classes.heroContent, classes.mainSection) } direction="row" justify="flex-start" alignItems="center">
          <Grid item xs={6} sm={6}>
            <Typography className={classes.mainSubHeading} variant="body1" fontWeight="fontWeightMedium">Let your customers see<br />how much storage space they need.</Typography>
            <Typography variant="h1" className={classes.mainHeader}>Instantly.</Typography>
            <Button component={Link} to="/pricing" variant="contained" color="default" size="large">Sign up free</Button>
          </Grid>
        </Grid>
      </section>
      <section className={classes.features} id="features-section">
        <Grid container spacing={3} className={classes.mainSection} alignItems="center">
          <Grid item xs={12} md={12}>
            <SimpleHeading mb={16}>Features</SimpleHeading>
          </Grid>
          <Grid item xs={3} md={3}>
            <img src={nextLevel} />
          </Grid>
          <Grid item xs={9} md={9}>
            <Typography variant="h4" component="h3" className={classes.mb1}>Next-Level Service</Typography>
            <Typography variant="body1">A storage calculator like no other. Calcumate provides your customers with a state-of-the-art tool that shows, in 3D, EXACTLY how much space they need.</Typography>
          </Grid>

          <Grid item xs={3} md={3}>
            <img src={fullyCustom}/>
          </Grid>
          <Grid item xs={9} md={9}>
            <Typography variant="h4" component="h3" className={classes.mb1}>Fully Customisable</Typography>
            <Typography variant="body1">Whether you have mobile or fixed storage units, Calcumate can be fully customised to display all dimensions. Calculations can also be made in either the imperial or metric system.</Typography>
          </Grid>

          {/* <Grid item xs={3} md={3}>
            <img src={simpleSolution}/>
          </Grid>
          <Grid item xs={9} md={9}>
            <Typography variant="h4" component="h3" className={classes.mb1}>Simple Solutions</Typography>
            <Typography variant="body1">No more complaints about items not fitting or being sold the wrong sized unit. Calcumate does it all, as well as providing a bespoke loading plan the customer can keep.</Typography>
          </Grid> */}

          <Grid item xs={3} md={3}>
            <img src={easyIntegration}/>
          </Grid>
          <Grid item xs={9} md={9}>
            <Typography variant="h4" component="h3" className={classes.mb1}>Easy Integration</Typography>
            <Typography variant="body1">Calcumate can be easily plugged into most websites built on standard platforms.</Typography>
          </Grid>
          
          <Grid item xs={3} md={3}>
            <img src={unbeatablePricing}/>
          </Grid>
          <Grid item xs={9} md={9}>
            <Typography variant="h4" component="h3" className={classes.mb1}>Unbeatable Pricing</Typography>
            <Typography variant="body1">Get your facility set up with Calcumate from just $29 per month.</Typography>
          </Grid>
        </Grid>
      </section>
      <section className={classes.caseStudy}>
        <Grid container spacing={4} className={classes.mainSection} alignItems="center">
          <Grid item xs={12} md={12}>
            <SimpleHeading mb={10}>Case study</SimpleHeading>
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography variant="body1"><strong>TAXIBOX</strong> experienced a dramatic increase to on-page traffic, leads and conversions as a result of integrating Calcumate onto their website. Since going live in mid-2018, more than 1 in 3 users now visit their new calculator page, <strong>resulting in a 23.7% increase in bookings (year-on-year)</strong> over their previous storage estimator. Plus 100s of additional monthly leads and a significantly more accurate estimation of storage space required.</Typography>
          </Grid>
          <Grid item xs={3} md={3}>
            <Grid container className={classes.circleFeature} justify="center" alignItems="center">
              <Grid item xs={12} md={12}>
                <Typography variant="h3" className={classes.featureMain}>23.7%</Typography>
                <Typography variant="body2" className={classes.featureSub}>Increase in Bookings</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3} md={3}>
            <Grid container className={classes.circleFeature} justify="center" alignItems="center">
              <Grid item xs={12} md={12}>
                <Typography variant="h3" className={classes.featureMain}>100s</Typography>
                <Typography variant="body2" className={classes.featureSub}>of Additional Leads</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3} md={3}>
            <Grid container className={classes.circleFeature} justify="center" alignItems="center">
              <Grid item xs={12} md={12}>
                <Typography variant="h3" className={classes.featureMain}>3<sup>rd</sup></Typography>
                <Typography variant="body2" className={classes.featureSub}>Most Visited Page</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3} md={3}>
            <Grid container className={classes.circleFeature} justify="center" alignItems="center">
              <Grid item xs={12} md={12}>
                <Typography variant="h3" className={classes.featureMain}>50%</Typography>
                <Typography variant="body2" className={classes.featureSub}>Increase in Page Value</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12}>
            <img src={tbCaseStudy} style={{maxWidth:"100%", height:"auto"}}/>
          </Grid>
          <Grid item xs={12} md={12} style={{textAlign:"center"}}>
          <Button variant="contained" color="secondary" size="large" onClick={()=>{window.location.href="https://www.taxibox.com.au/faq/storage-calculator/"}}>VIEW PROJECT</Button>
          </Grid>
        </Grid>
      </section>
      <section className={classes.demo} id="demo-section">
        <SimpleHeading mb={40} theme="light">Demo</SimpleHeading>
        <div className={classes.integration}>INTEGRATION HERE</div>
      </section>
      <section className={classes.startForFree}>
        <SimpleHeading mb={30}>Start for free</SimpleHeading>
        <Typography variant="subtitle2" className={classes.startFreeTxt}>Start your 30-day free trial now</Typography>
        <Button component={Link} to="/pricing" variant="contained" color="primary" size="large">Sign up free</Button>
      </section>
      <Footer />
    </div>
  )
}

export default HomePage