import {
  primaryColour,
  whiteColor
} from "assets/jss/material-dashboard-react.js";

const footerStyle = {
  footerContainerOne: {
    maxWidth: 700,
    margin:"0 auto"
  },
  startFreeTxt: {
    color: primaryColour[900]
  },
  startFreeTxtWhite: {
    color: whiteColor[900]
  },
  footerContainerTwo: {
    backgroundColor: primaryColour[900],
    color: whiteColor,
    padding: "15px 46px",
    "& img": {
      width: 30,
      height: "auto",
      marginRight: 10,
      verticalAlign: "middle"
    }
  },
  footer: {
    margin: 0,
    padding: 0
  }
}

export default footerStyle
