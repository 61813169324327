import { primaryColour,secondaryColour, whiteColor } from "assets/jss/material-dashboard-react"

const adminIntegrationItemStyle = {
  itemCard: {
    borderRadius: 15,
    position: "relative",
    textAlign: "center",
    "&:hover > .MuiSvgIcon-root": {
      display: "initial"
    },
    minHeight: 225
  },
  itemCardAction: {
    padding: 15
  },
  cardActive: {
    backgroundColor: primaryColour[100]
  },
  cardEdit: {
    display: "none",
    position: "absolute",
    color: primaryColour[900],
    right: 15,
    top: 15,
    zIndex: 10,
    cursor: "pointer"
  },
  itemCheckedIcon: {
    position: "absolute",
    color: primaryColour[900],
    left: 15,
    top: 15
  },
  itemCardImg:{
    width:"auto",
    height:"115px",
    margin:"10px auto",
  },
  closeFab: {
    position: "absolute",
    right:12,
    top:12
  },
  customDialogContent: {
    padding: 8,
    textAlign: "center"
  },
  customDialog: {
    "& .MuiPaper-root": {
      minWidth: 350
    }
  },
  customDialogImage: {
    maxWidth: 150,
    maxHeight: 150,
    paddingBottom: 20
  },
  qtyControl: {
    backgroundColor: secondaryColour[900],
    borderRadius: 15
  },
  controlBtn:{
    color: secondaryColour[400],
    cursor: "pointer",
    paddingTop: 5,
    paddingRight: 10,
    paddingBottom: 0,
    paddingLeft: 10,
    "&:hover": {
      color: whiteColor
    }
  },
}

export default adminIntegrationItemStyle;
