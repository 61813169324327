import React, { useContext, useEffect } from "react"

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles"
import { Grid, Button, Typography, Divider } from "@material-ui/core"

// Core components
import { useGlobalState } from "hooks/useGlobalState"

// Other components
import AdminPanels from "components/AdminPanels/AdminPanels"

// Style
import styles from "assets/jss/material-dashboard-react/layouts/integrationStyle"
import { Link } from "react-router-dom"

const useStyles = makeStyles(styles)

const ConfirmStep = props => {
  const classes = useStyles();
  const dispatchState = useContext(useGlobalState.Dispatch)

  const { theRef } = props

  /* eslint-disable */
  useEffect(() => {
    dispatchState({ type: 'integration-data', payload: { integrationStepperStep: 5 }}) // Set stepper step number 6
    theRef.current.scrollTop = 0 //scrollTop back to 0 when changing views
  }, []);
  /* eslint-enable */
  
  return (
    <form noValidate autoComplete="off" className={classes.standardForm}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5" component="h1">Confirmation</Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" component="h3">Are you happy with the live preview?</Typography>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="outlined"
            className={classes.buttonSpacing}
            color="primary"
            size="large"
            fullWidth
            component={Link}
            to={'/admin/my-calcumate/new?step=design'}
          >Keep editing</Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="contained"
            className={classes.buttonSpacing}
            color="primary"
            size="large"
            fullWidth
            component={Link}
            to={'/admin/my-calcumate/new?step=confirmed'}
          >Yes, publish now</Button>
        </Grid>
        <Grid item xs={12}>
          <AdminPanels
            theHeader={false}
            headerAction={false}
            fabCodeAction={false}
            fabCloneAction={false}
            fabEditAction={false}
            fabDeleteAction={false}
            panelTheme="dark"
          >
            <Typography variant="h5">Need assistance?</Typography>
            <Typography variant="body2">For further assistance, please <Link to="/admin/support">contact us</Link>. We’re here to help!</Typography>
          </AdminPanels>
        </Grid>
      </Grid>
      
    </form>
  )
}

export default ConfirmStep
