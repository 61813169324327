import React from "react"

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles"
import { Grid, Fab} from "@material-ui/core"
import NavigateBeforeRounded from '@material-ui/icons/NavigateBeforeRounded'
import CloseRounded from '@material-ui/icons/CloseRounded'

// core components

// Other components

// Style
import styles from "assets/jss/material-dashboard-react/layouts/transactionalStyle"

const useStyles = makeStyles(styles)

const AdminTransactionalHeader = props => {
  const classes = useStyles()
  const { backBtn, closeBtn } = props

  return (
    <Grid
      container
      direction="row"
      justify="space-between"
      alignItems="center"
      className={classes.adminTHeader}
    >
      <Grid item>
        {backBtn &&
          <Fab
            color="primary"
            aria-label="Edit"
            size="medium"
            onClick={backBtn}
          >
            <NavigateBeforeRounded />
          </Fab>
        }
      </Grid>
      <Grid item>
        {closeBtn &&
          <Fab
            color="primary"
            aria-label="Edit"
            size="medium"
            onClick={closeBtn}
          >
            <CloseRounded />
          </Fab>
        }
      </Grid>
    </Grid>
  )
}

export default AdminTransactionalHeader
