import React, { useContext } from "react"

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles"
import EditRounded from '@material-ui/icons/EditRounded'
import ClearRounded from '@material-ui/icons/ClearRounded'
import { Typography, Grid, Card, Fab, Radio, List, ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction, Collapse } from "@material-ui/core"


// core components
import classNames from "classnames"

// Other components
import CustomPricingSwitch from "./CustomPricingPanel/CustomPricingSwitch"
import { useGlobalState } from "hooks/useGlobalState"

// Style
import styles from "assets/jss/material-dashboard-react/components/customPricingStyle"

const useStyles = makeStyles(styles)

const CustomPricingWidget = () => {
  const classes = useStyles()
  const dispatchState = useContext(useGlobalState.Dispatch)
  const state = useContext(useGlobalState.State)
  return (
    <Card variant="outlined" className={classes.CustomPricingW}>
      <Grid container spacing={0} direction="row" justify="space-between" alignItems="center" className={classes.planSelected}>
        <Grid>
          <Typography
            variant="overline"
            className={classes.CustomPricingOverline}>
              {state.calcumatePlans[state.highlightedPlan].thePlan}
          </Typography>
          <Typography
            variant="subtitle2" component="p">
              <strong>${state.cmPlanPayRec ? state.calcumatePlans[state.highlightedPlan].thePrice[0] : state.calcumatePlans[state.highlightedPlan].thePrice[1]}
              {state.cmPlanPayRec ? "/yr" : "/mo"}. </strong>
              {state.cmPlanPayRec ? state.calcumatePlans[state.highlightedPlan].theDiscount.yr : state.calcumatePlans[state.highlightedPlan].theDiscount.mo}
          </Typography>
        </Grid>
        <Grid>
          <Fab
            color="primary"
            aria-label="Change plan"
            size="medium" 
            onClick={() => dispatchState({ type: 'root-state', payload: { cmPricingWidgetVisible: (state.cmPricingWidgetVisible ? false : true) } })}
            >
            {state.cmPricingWidgetVisible ? (
               <ClearRounded /> 
            ):(
              <EditRounded />
            )}
          </Fab>
        </Grid>
      </Grid>
      <Collapse in={state.cmPricingWidgetVisible}>
        <Grid container className={classes.planList}>
          <Grid item xs={12} sm={12} md={12} className={classes.switchPadding}>
            <CustomPricingSwitch />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <List className={classes.planListPad}>
              {state.calcumatePlans.map((prop, key) => {
                const labelId = `checkbox-list-label-${key}`
                return (
                  <ListItem
                    key={key}
                    role={undefined}
                    button
                    className={classNames(classes.planListItem, classes.planSelected)}
                    onClick={() => dispatchState({ type: 'root-state', payload: { highlightedPlan: key } })}
                    >
                    <ListItemIcon>
                      <Radio
                        edge="start"
                        checked={key === state.highlightedPlan}
                        color="primary"
                        name="plan"
                        tabIndex={-1}
                        value={prop.thePlan}
                      />
                    </ListItemIcon>
                    <ListItemText id={labelId}>
                      <Typography variant="overline" className={classes.CustomPricingOverline}>{prop.thePlan}</Typography>
                      <Typography variant="subtitle2" component="p">{(state.cmPlanPayRec ? prop.theDiscount.yr : prop.theDiscount.mo)}</Typography>
                    </ListItemText>
                    <ListItemSecondaryAction>
                      <Typography className={classes.priceFont} variant="h2">
                        ${state.cmPlanPayRec ? prop.thePrice[0] : prop.thePrice[1]}
                        <Typography variant="subtitle1" component="p" display="inline">
                          {state.cmPlanPayRec ? "/yr" : "/mo"}
                        </Typography>
                      </Typography>
                    </ListItemSecondaryAction>
                  </ListItem>
                )
              })}
            </List>
          </Grid>
        </Grid>
      </Collapse>
    </Card>
  )
}

export default CustomPricingWidget
