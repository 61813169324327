import React from "react"

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles"
import { Grid, Typography } from "@material-ui/core"

// Core components

// Other components
import CustomPricingPanel from "components/CustomPricing/CustomPricingPanels"

// Style
import styles from "assets/jss/material-dashboard-react/layouts/transactionalStyle"

const useStyles = makeStyles(styles)

const Pricing = () => {
  const classes = useStyles();

  return (
    <Grid container spacing={0} justify="center" className={classes.cFormContaner}>
      <Grid item xs={12} sm={7} md={5}>
        <Typography variant="h1" className={classes.headerOneLine}>Start now for free</Typography>
        <Typography variant="subtitle1" component="p" className={classes.subTitleTwo} align="center">No commitments, cancel at any time and you won’t be charged until after your free month.</Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <CustomPricingPanel />
      </Grid>
    </Grid>
  );
}

export default Pricing
