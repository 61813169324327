import React from "react"

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles"
import { Grid, Typography, TextField, Button } from "@material-ui/core"

// Core components
import { Link } from "react-router-dom"

// Other components

// Style
import styles from "assets/jss/material-dashboard-react/layouts/transactionalStyle"
const useStyles = makeStyles(styles)

const RecoveryForm = () => {
  const classes = useStyles()
  return (
    <form noValidate autoComplete="off" className={classes.standardForm}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="body1" align="center">Enter your email address. We’ll send you an email with a link to reset your password.</Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField id="p-login-email" label="Email" variant="outlined" type="email" fullWidth />
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          <Button component={Link} to="/login" variant="outlined" className={classes.buttonSpacing} color="primary" size="large" fullWidth>Back</Button>
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          <Button component={Link} to="/password-request/requested" variant="contained" className={classes.buttonSpacing} color="primary" size="large" fullWidth>Send</Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default RecoveryForm
