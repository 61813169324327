import React, { useContext, useEffect } from "react"

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles"
import { Grid, Button, TextField } from "@material-ui/core"

// Core components
import { Link } from "react-router-dom"
import PasswordInput from "components/PasswordInput/PasswordInput"

// Other components
import { useGlobalState } from "hooks/useGlobalState"

// Style
import styles from "assets/jss/material-dashboard-react/layouts/transactionalStyle"

const useStyles = makeStyles(styles)

const AccountStep = () => {
  const classes = useStyles()
  const dispatchState = useContext(useGlobalState.Dispatch)

  /* eslint-disable */
  useEffect(() => {
    dispatchState({ type: 'root-state', payload: { signUpStepperStep: 0 }}) // Set stepper step number 1
  }, []);
  /* eslint-enable */

  return (
    <form noValidate autoComplete="off" className={classes.standardForm}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField id="p-signup-email" label="Email" variant="outlined" type="email" fullWidth />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <TextField id="p-signup-fname" label="First name" variant="outlined" type="text" fullWidth />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <TextField id="p-signup-lname" label="Last name" variant="outlined" type="text" fullWidth />
        </Grid>
        <Grid item xs={12}>
          <TextField id="p-signup-phone" label="Phone number (Optional)" variant="outlined" type="phone" fullWidth />
        </Grid>
        <Grid item xs={12}>
          <TextField id="p-signup-company" label="Company name (Optional)" variant="outlined" type="text" fullWidth />
        </Grid>
        <Grid item xs={12}>
          <PasswordInput
            uniqueID="p-signup-password"
            uniqueLabel="Password"
          />
        </Grid> 
        <Grid item xs={12}>
          <Button
            variant="contained"
            component={Link}
            to={'/sign-up/payment'}
            className={classes.buttonSpacing}
            color="primary"
            size="large"
            fullWidth
          >Next</Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default AccountStep
