import React, {useContext, createRef, useEffect } from "react"

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles"
import { Fab, Slide } from "@material-ui/core"
import CloseRounded from '@material-ui/icons/CloseRounded'

// Core components
import classNames from 'classnames'
import { useGlobalState } from "hooks/useGlobalState"
import PerfectScrollbar from "perfect-scrollbar"

// Other components
import IntegrationPreview from "views/IntegrationCreation/IntegrationPreview"
import OptionStepAdvanced from "views/IntegrationCreation/steps/OptionStepAdvanced"
import OptionStep from "views/IntegrationCreation/steps/OptionStep"
import UnitsStep from "views/IntegrationCreation/steps/UnitsStep"
import UnitsStepAdvanced from "views/IntegrationCreation/steps/UnitsStepAdvanced"
import CategoriesStep from "views/IntegrationCreation/steps/CategoriesStep"
import CategoriesStepAdvanced from "views/IntegrationCreation/steps/CategoriesStepAdvanced"
import PresetsStep from "views/IntegrationCreation/steps/PresetsStep"
import PresetsStepAdvanced from "views/IntegrationCreation/steps/PresetsStepAdvanced"
import DesignStep from "views/IntegrationCreation/steps/DesignStep"
import ConfirmStep from "views/IntegrationCreation/steps/ConfirmStep"
import ConfirmedStep from "views/IntegrationCreation/steps/ConfirmedStep"
import IntegrationHeading from "components/IntegrationHeading/IntegrationHeading"
import IntegrationStepper from "views/IntegrationCreation/IntegrationStepper"

// Style
import styles from "assets/jss/material-dashboard-react/layouts/integrationStyle"
import "perfect-scrollbar/css/perfect-scrollbar.css"

/* eslint-disable */
let panelOneScroll
let panelTwoScroll
/* eslint-enable */

const useStyles = makeStyles(styles)

const IntegrationCreation = props => {
  const classes = useStyles();
  const dispatchState = useContext(useGlobalState.Dispatch)
  const state = useContext(useGlobalState.State)
  const sidePanelOne = createRef(); // ref to help us initialize PerfectScrollbar on windows devices
  const sidePanelTwo = createRef(); // ref to help us initialize PerfectScrollbar on windows devices
  
  const panelOneRender = (section) => {
    switch(props.history.location.search) {
      case "?step=units":
        return section === "primary" ? <UnitsStep theRef={sidePanelOne} /> : <UnitsStepAdvanced theRef={sidePanelTwo} />
      case "?step=categories":
        return section === "primary" ? <CategoriesStep theRef={sidePanelOne} /> : <CategoriesStepAdvanced theRef={sidePanelTwo} />
      case "?step=presets":
        return section === "primary" ? <PresetsStep theRef={sidePanelOne} /> : <PresetsStepAdvanced theRef={sidePanelTwo} />
      case "?step=design":
        return section === "primary" ? <DesignStep theRef={sidePanelOne} /> : ""
      case "?step=confirm":
        return section === "primary" ? <ConfirmStep theRef={sidePanelOne} /> : ""
      case "?step=confirmed":
        return section === "primary" ? <ConfirmedStep theRef={sidePanelOne} /> : ""
      default:
        return section === "primary" ? <OptionStep theRef={sidePanelOne} /> : <OptionStepAdvanced theRef={sidePanelTwo} />
    }
  }

  // initialize and destroy the PerfectScrollbar plugin
  /* eslint-disable */
  useEffect(() => {
    panelOneScroll = new PerfectScrollbar(sidePanelOne.current, {
      suppressScrollX: true,
      suppressScrollY: false
    })
    panelTwoScroll = new PerfectScrollbar(sidePanelTwo.current, {
      suppressScrollX: true,
      suppressScrollY: false
    })
    if (navigator.platform.indexOf("Win") > -1) {
      panelOneScroll = new PerfectScrollbar(sidePanelOne.current, {
        suppressScrollX: true,
        suppressScrollY: false
      });
      panelTwoScroll = new PerfectScrollbar(sidePanelTwo.current, {
        suppressScrollX: true,
        suppressScrollY: false
      });
      document.body.style.overflow = "hidden";
    }
    
  }, []);
  /* eslint-enable */

  return (
    <div className={classes.integrationWrapper}>
      
      {/* Primary panel */}
      <div className={classNames(classes.panelOne, {[classes.panelOneHide]: state.integrationData.integrationPanelsFS})}>
        <IntegrationHeading />
        <div className={classes.panelOneWrapper} ref={sidePanelOne}>
          {panelOneRender("primary")}
        </div>
      </div>

      {/* Secondary panel */}
      <Slide in={state.integrationData.integrationSecPanel} direction="right" timeout={500}>
        <div className={classes.panelTwo} ref={sidePanelTwo}>
          <Fab
            color="primary"
            aria-label="Edit"
            size="medium"
            className={classes.closeFab}
            onClick={()=>{dispatchState({ type: "integration-data", payload: { integrationSecPanel: false } })}}
          >
            <CloseRounded />
          </Fab>
          <div className={classes.panelTwoWrapper}>
            <div className={classes.contentTable}>
              {panelOneRender("secondary")}
            </div>
          </div>
        </div>
        </Slide>

      {/* Stepper */}
      <IntegrationStepper
        urlParam={props.history.location.search}
      />

      {/* Integration preview */}
      <div className={classes.integrationIndex}>
        <IntegrationPreview />
      </div>
    </div>
  )
}

export default IntegrationCreation
