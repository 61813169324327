import React from "react"

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles"
//import { Grid, Typography } from "@material-ui/core"

// Core components

// Other components
//import CustomPricingPanel from "components/CustomPricing/CustomPricingPanels"

// Style
import styles from "assets/jss/material-dashboard-react/layouts/integrationStyle"

const useStyles = makeStyles(styles)

const IntegrationPreview = () => {
  const classes = useStyles();

  return (
    <div className={classes.integrationComponent}>
      <h1>Integration preview here</h1>
    </div>
  )
}

export default IntegrationPreview
