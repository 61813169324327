import React from "react"

const Integration = props => {
  const { children } = props

  return (
    <div>{children}</div>
  )
}

export default Integration
