const InitState = {
  isLoggedIn: false,
  signUpStepperStep: 0,
  signUpStepperSteps: ["Account", "Payment", "Confirmation"],
  accountCurrentCountryValue: "aus",
  accountCurrentCountryList: [
    {
      country: "Australia",
      value: "aus"
    },
    {
      country: "Japan",
      value: "jpn"
    },
    {
      country: "Chile",
      value: "chl"
    }
  ],
  pages: {
    home:{
      title: "Homepage"
    },
    signup: {
      title: "Sign up",
    },
    login: {
      title: "Login"
    },
    passrequest: {
      title: "Password request"
    },
    contactUs: {
      title: "Contact us"
    }
  },
  cmPlanPayRec: false, //false=montly true=yearly 
  cmPricingWidgetVisible: false,
  recommendedPlan: "Professional",
  highlightedPlan: 0,
  currentPlan:"Starter",
  currentPlanPrice: 29,
  currentPlanPriceRec:"/mo",
  signUpPlanUrl: "/sign-up",
  upDownGradePlanUrl: "/admin/my-account/my-subscription/modify",
  cancelPlanUrl: "/admin/my-account/my-subscription/cancel",
  calcumatePlans: [
    {
      isRecommended: false,
      theAction: "/sign-up/",
      thePlan:"Starter",
      thePrice: ["313.2", "29"],
      theDiscount: {
        yr: "10% discount applied",
        mo: "Get 10% off on yearly plans"
      },
      theFeatureList:["1 - 5 Facilities", "Free setup", "Custom design", "Custom units", "Up to 20 items selected", "Custom languages", "Metric or Imperial setup"],
    },
    {
      isRecommended: true,
      theAction:"/sign-up?plan=professional",
      thePlan:"Professional",
      thePrice: ["529.2", "49"],
      theDiscount: {
        yr: "10% discount applied",
        mo: "Get 10% off on yearly plans"
      },
      theFeatureList:["5 - 15 Facilities", "Free setup", "Custom design", "Custom units", "Up to 20 items selected", "Custom languages", "Metric or Imperial setup"],
    },
    {
      isRecommended: false,
      theAction:"/sign-up?plan=enterprise",
      thePlan:"Enterprise",
      thePrice: ["1069.2", "99"],
      theDiscount: {
        yr: "10% discount applied",
        mo: "Get 10% off on yearly plans"
      },
      theFeatureList:["15+ Facilities", "Free setup", "Custom design", "Custom units", "Up to 20 items selected", "Custom languages", "Metric or Imperial setup"],
    }
  ],
  adminNavigationMobile: false,
  integrationData:{
    integrationName:"My integration",
    integrationPanelsFS: false,
    integrationSecPanel: false,
    integrationStepperStep: 0,
    integrationStepperSteps: ["Options", "Units", "Categories", "Presets", "Design", "Confirm"],
    integrationURL: "",
    integrationActiveLanguage: "en",
    integrationLanguages: [
      {
        language: "English",
        value: "en"
      },
      {
        language: "German",
        value: "de"
      },
      {
        language: "Japanese",
        value: "jp"
      },
      {
        language: "French",
        value: "fr"
      },
      {
        language: "Danish",
        value: "da"
      },
      {
        language: "Norwegian",
        value: "no"
      }
    ],
    integrationStorageType: "self-storage",
    integrationMeasurement: "m",
    integrationCtaURL:"",
    integrationIncQty: false,
    integrationIncID: false,
    integrationCTALabel: "Book now",
    integrationCTAType: false,
    integrationCTATarget: "_self",
    integrationIsNewHelper: false,
    integrationEditing: 0,
    integrationNewUnit: {
      ID: 0,
      unitName: "",
      unitWidth: "",
      unitHeight: "",
      unitDepth: "",
    },
    integrationUnits:[
      {
        ID: 0,
        unitName: "3x3x4 Unit",
        unitWidth: "3",
        unitHeight: "3",
        unitDepth: "3",
      },
      {
        ID: 1,
        unitName: "3x4x4 Unit",
        unitWidth: "3",
        unitHeight: "4",
        unitDepth: "4",
      }
    ],
    integrationNewCategory: {
      ID: 0,
      catName: "",
      itemsAssigned:[]
    },
    integrationCategories:[
      {
        ID: 0,
        catName: "Kitchen",
        itemsAssigned:[0, 1]
      }
    ],
    integrationNewPreset: {
      ID: 0,
      preName: "",
      itemsAssigned:[],
      qtyAssigned: []
    },
    integrationPresets:[
      {
        ID: 0,
        preName: "1 Bedroom",
        itemsAssigned:[0, 2],
        qtyAssigned: [1, 2]
      },
      {
        ID: 1,
        preName: "2 Bedroom",
        itemsAssigned:[0, 1],
        qtyAssigned: [1, 2]
      }
    ],
    itemLibrary: [
      {
        ID:0,
        defaultName: "Item 1",
        de: "Bettkasten",
        customName: "",
        itemThumbnail: "https://calcumate.co/wp-content/themes/calcumate-2019/dist/images/slider-furniture-icons/queen-mattress-scale-240.png",
        dimention: ["1.1", "2.1"],
        w: ["20", "30"],
        h: ["20", "30"],
        d: ["20", "30"],
        group: ["kitchen", "bathroom"]
      },
      {
        ID:1,
        defaultName: "Item 2",
        de: "Bettkasten",
        customName: "",
        itemThumbnail: "https://calcumate.co/wp-content/themes/calcumate-2019/dist/images/slider-furniture-icons/sofa-2-scale.png",
        dimention: ["1.1", "2.1"],
        w: ["20", "30"],
        h: ["20", "30"],
        d: ["20", "30"],
        group: ["kitchen", "bathroom"]
      },
      {
        ID:2,
        defaultName: "Item 3",
        de: "Bettkasten",
        customName: "",
        itemThumbnail: "https://calcumate.co/wp-content/themes/calcumate-2019/dist/images/slider-furniture-icons/bookcase-scale.png",
        dimention: ["1.1", "2.1"],
        w: ["20", "30"],
        h: ["20", "30"],
        d: ["20", "30"],
        group: ["kitchen", "bathroom"]
      },
    ],
    integrationActiveFont: "Roboto",
    integrationFontFamily: ["Roboto", "Arial", "Times"],
    integrationPrimaryColour:"#1CB2E6",
    integrationSecondaryColour:"#A380DB",
    integrationActiveBgStyle: "solid",
    integrationGradientOne:"#1CB2E6",
    integrationGradientTwo:"#A380DB",
    integrationButtonColour:"#1CB2E6",
    integrationButtonTextColour:"#A380DB",
  }
}

export default InitState
