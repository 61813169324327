const muiPaperStyle = {
  MuiPaper: {
    root: {
      "&.MuiDialog-paper.MuiDialog-paper": {
        borderRadius: 15,
        padding:20
      }
    },
  },
};

export default muiPaperStyle;
