/*eslint-disable*/
import React, { useContext } from "react"

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import { Grid, TextField, Button, Typography } from "@material-ui/core"

// core components
import { Link } from "react-router-dom"
import { useGlobalState } from "hooks/useGlobalState"

// Other components
import PasswordInput from "components/PasswordInput/PasswordInput"
import AdminTransactionalHeader from "components/AdminTransactionalHeader/AdminTransactionalHeader"
import CustomPricingPanel from "components/CustomPricing/CustomPricingPanels"

// Style
import styles from "assets/jss/material-dashboard-react/layouts/transactionalStyle"

const useStyles = makeStyles(styles)

const AdminMySubscription = props => {
  const classes = useStyles();
  let state = useContext(useGlobalState.State)
  
  return (
    <Grid container spacing={0} justify="center" className={classes.cFormContaner}>
      <Grid item xs={12} sm={12} md={12}>
        <AdminTransactionalHeader
          backBtn={false}
          closeBtn={() => {props.history.push("/admin/my-account")}}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} className={classes.cWidthLimiter}>
        <Typography variant="overline">My Subscription</Typography>
        <Typography variant="h1" className={classes.headerTwoLine}>{state.currentPlan} ${state.currentPlanPrice}{state.currentPlanPriceRec}</Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} className={classes.standardForm}>
        <CustomPricingPanel />
      </Grid>
    </Grid>
  );
}

export default AdminMySubscription
