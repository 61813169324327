import { primaryColour, secondaryColour, whiteColor } from "assets/jss/material-dashboard-react";

const muiThemeTypography = {
  typography: {
    fontFamily: ['futura-pt', 'sans-serif'],
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600,
    h1: {
      fontWeight: 600,
      fontSize: "2.635rem",
      color: primaryColour[900],
    },
    h2: {
      fontWeight: 600,
      fontSize: "2.125rem",
      color: primaryColour[900],
    },
    h3: {
      fontWeight: 600,
      fontSize: "1.875rem",
      color: primaryColour[900],
    },
    h4: {
      fontWeight: 600,
      fontSize: "1.75rem",
      color: primaryColour[900],
    },
    h5: {
      fontWeight: 600,
      fontSize: "1.25rem",
      color: primaryColour[900],
    },
    h6: {
      fontWeight: 600,
      fontSize: "1.125rem",
      color: primaryColour[900],
    },
    subtitle1: {
      fontWeight: 500,
      textTransform: "initial",
    },
    subtitle2: {
      fontWeight: 500,
      textTransform: "initial",
    },
    body1: {
      fontWeight: 400,
    },
    body2: {
      fontWeight: 400,
    },
    button: {
      fontSize: "1rem",
      textTransform: "uppercase",
    },
    caption: {
      fontSize: "0.8125rem",
    },
    overline: {
      color: primaryColour[400],
      fontWeight: 500,
      lineHeight: 1.2,
      fontSize: "0.8125rem",
      letterSpacing: 2,
    },
  },
  palette: {
    primary: {
      light: primaryColour[700], 
      main: primaryColour[800],
      dark: primaryColour[900],
      contrastText: whiteColor,
    },
    secondary: {
      light: secondaryColour[600], 
      main: secondaryColour[700],
      dark: secondaryColour[900],
      contrastText: whiteColor,
    },
  },
  status: {
    danger: 'orange',
  },
};

export default muiThemeTypography;
