import { primaryColour } from "assets/jss/material-dashboard-react";
import { grayColor } from "assets/jss/material-dashboard-react";

const customPricingStyle = {
  pricingPanels: {
    marginTop: 20,
    marginBottom: 20,
  },
  PricingWidgetTextColor: {
    color: grayColor[6],
    cursor: "pointer"
  },
  PricingWidgetTextColorActive: {
    color: primaryColour[900],
  },
  pricingSwitch: {
    "& .MuiSwitch-colorPrimary .Mui-checked, & .MuiSwitch-colorPrimary": {
      color: primaryColour[900],
    },
    "& .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track, & .MuiSwitch-colorPrimary + .MuiSwitch-track": {
      backgroundColor: primaryColour[900],
    },
  },
  pricingPaper: {
    minWidth: 265,
    padding: 15,
    textAlign: "center",
    boxShadow: "0 1px 4px rgba(0,0,0,0.25)",
    borderRadius: 15,
  },
  pricingRecommended: {
    backgroundColor: primaryColour[50],
  },
  priceDiscount: {
    color: grayColor[7],
  },
  priceFont: {
    fontSize: "2.375rem",
  },
  priceList: {
    maxWidth: 160,
    margin: "0 auto",
    color: grayColor[7],
    paddingTop: 24,
    paddingBottom: 24,
    "& .MuiListItem-dense": {
      paddingTop: 0,
      paddingBottom: 0,
    },
    "& .MuiListItemText-root": {
      marginTop: 0,
      marginBottom: 0,
      paddingBottom: 3,
    },
    "& .MuiListItemIcon-root": {
      minWidth: 25,
    },
    "& .MuiSvgIcon-root": {
      color: primaryColour[500],
    },
    "& .MuiSvgIcon-fontSizeSmall": {
      fontSize: "1rem",
    }
  },
  CustomPricingW: {
    borderColor: primaryColour[800],
    borderRadius: 15,
    color: primaryColour[900],
  },
  CustomPricingOverline: {
    lineHeight: 1,
  },
  planSelected: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 25,
    paddingRight: 25,
    "& .MuiListItemIcon-root": {
      minWidth: 40,
    }
  },
  planList: {
    backgroundColor: primaryColour[50],
    borderRadius: 15,
  },
  switchPadding: {
    paddingTop: 14,
    paddingBottom: 14
  },
  planListItem: {
    borderTop: "solid " + primaryColour[100] + " 1px"
  },
  planListPad: {
    padding: 0,
    "& .MuiTypography-body1": {
      lineHeight: "100%"
    }
  },
};

export default customPricingStyle;
