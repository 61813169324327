import React, { useContext } from "react"

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles"
import { Typography, Paper, List, ListItem, ListItemText, ListItemIcon, Button } from "@material-ui/core"
import CheckCircleRounded from '@material-ui/icons/CheckCircleRounded'

// core components
import classNames from "classnames"

// Other components
import { useGlobalState } from "hooks/useGlobalState"

// Style
import styles from "assets/jss/material-dashboard-react/components/customPricingStyle"
import { Link } from "react-router-dom"

const useStyles = makeStyles(styles)

const CustomPricingPanel = (props) => {
  const classes = useStyles();
  let state = useContext(useGlobalState.State)
  const { index, thePlan, theDiscount, thePay, thePrice, theFeatureList } = props

  const isHighlightedPanel = () => {
    if(!state.isLoggedIn) {
      if( thePlan === state.recommendedPlan ) {
        return true
      }
    }
    return false
  }
  const buttonActionAndStyle = () => {
    if(state.isLoggedIn) {
      if( thePlan === state.currentPlan ) {
        return (
          <Button
            component={Link}
            to={state.cancelPlanUrl + "?plan=" + thePlan.toLowerCase()}
            color="default"
            size="small" 
            fullWidth
          >
              Cancel subscription
          </Button>
        )
      }else{
        return (
          <Button
            component={Link}
            to={state.upDownGradePlanUrl + "?plan=" + thePlan.toLowerCase()}
            variant="contained"
            color="primary"
            size="small" 
            fullWidth
          >
              Get {thePlan}
          </Button>
        )
      }
    }
    return (
      <Button
        component={Link}
        to={state.signUpPlanUrl + "?plan=" + thePlan.toLowerCase()}
        variant="contained"
        color="primary"
        size="small" 
        fullWidth
      >
          Get {thePlan}
      </Button>
    )
  }

  return (
    <Paper
      key={index}
      elevation={2}
      className={classNames(classes.pricingPaper, {[classes.pricingRecommended]: isHighlightedPanel()})}
    >
      <Typography variant="overline">{thePlan}</Typography>
      <Typography className={classes.priceFont} variant="h2">
        ${state.cmPlanPayRec ? thePrice[0] : thePrice[1]}
        <Typography variant="subtitle1" component="p" display="inline">{thePay}</Typography>
      </Typography>
      <Typography variant="caption" className={classes.priceDiscount}>
        {state.cmPlanPayRec ? theDiscount.yr : theDiscount.mo}
      </Typography>
      <List dense className={classes.priceList}>
        {theFeatureList.map((prop, key) => {
          return (
            <ListItem key={key} disableGutters>
              <ListItemIcon>
                <CheckCircleRounded fontSize="small" />
              </ListItemIcon>
              <ListItemText variant="caption" id={`price-list-${key}`}>
                {key === 0 ? (
                  <strong>{prop}</strong>
                ): prop }
              </ListItemText>
            </ListItem>
          )
        })}
      </List>
      {buttonActionAndStyle()}
    </Paper>
        
  );
}

export default CustomPricingPanel
