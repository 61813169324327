import React, { useContext, useEffect } from "react"

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles"
import { Grid, TextField, Button, Typography, Divider, InputAdornment } from "@material-ui/core"

// Core components
import { useGlobalState } from "hooks/useGlobalState"
import update from 'immutability-helper';

// Other components

// Style
import styles from "assets/jss/material-dashboard-react/layouts/integrationStyle"

const useStyles = makeStyles(styles)

const UnitsStepAdvanced = props => {
  const classes = useStyles();
  const dispatchState = useContext(useGlobalState.Dispatch)
  const state = useContext(useGlobalState.State)
  const { theRef } = props
  const cleanState = {
    ID: 0,
    unitName: "",
    unitWidth: "",
    unitHeight: "",
    unitDepth: ""
  }

  const saveNewUnit = () => () => {
    // Save new
    dispatchState({
      type: 'integration-data',
      payload: {
        integrationSecPanel: false,
        integrationUnits: [...state.integrationData.integrationUnits, state.integrationData.integrationNewUnit]
      }
    })
    //Clean new state
    dispatchState({
      type: 'integration-data',
      payload: {
        integrationNewUnit: {...cleanState}
      }
    })
  }

  const saveUnit = () => () => {
    const i = state.integrationData.integrationEditing
    let arrayToChange = state.integrationData.integrationUnits
    //Save new
    dispatchState({
      type: 'integration-data',
      payload: {
        integrationSecPanel: false,
        integrationUnits: update(arrayToChange, {[i]: {$set: state.integrationData.integrationNewUnit}})
      }
    })
    //Clean new state
    dispatchState({
      type: 'integration-data',
      payload: {
        integrationNewUnit: {...cleanState}
      }
    })
  }

  /* eslint-disable */
  useEffect(() => {
    theRef.current.scrollTop = 0 //scrollTop back to 0 when changing views
  }, []);
  /* eslint-enable */
  
  return (
    <Grid spacing={3} container>
      <Grid item xs={12}>
        <Typography variant="h3" component="h2">{state.integrationData.integrationIsNewHelper ? "New unit": "Edit unit"}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider className={classes.customDividerOne} style={{marginBottom: 20}} />
        <TextField
          id="p-integration-unit-name"
          label="Unit name"
          variant="outlined"
          type="text"
          value={state.integrationData.integrationNewUnit.unitName}
          onChange={(e)=>{dispatchState({ type: 'integration-data', payload: { integrationNewUnit:{...state.integrationData.integrationNewUnit, unitName: e.target.value }} })}}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="p-integration-unit-width"
          label="Unit width"
          variant="outlined"
          type="text"
          InputProps={{
            endAdornment: <InputAdornment position="end">{state.integrationData.integrationMeasurement}</InputAdornment>,
          }}
          value={state.integrationData.integrationNewUnit.unitWidth}
          onChange={(e)=>{dispatchState({ type: 'integration-data', payload: { integrationNewUnit:{...state.integrationData.integrationNewUnit, unitWidth: e.target.value }} })}}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="p-integration-unit-height"
          label="Unit height"
          variant="outlined"
          type="text"
          InputProps={{
            endAdornment: <InputAdornment position="end">{state.integrationData.integrationMeasurement}</InputAdornment>,
          }}
          value={state.integrationData.integrationNewUnit.unitHeight}
          onChange={(e)=>{dispatchState({ type: 'integration-data', payload: { integrationNewUnit:{...state.integrationData.integrationNewUnit, unitHeight: e.target.value }} })}}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="p-integration-unit-depth"
          label="Unit depth"
          variant="outlined"
          type="text"
          InputProps={{
            endAdornment: <InputAdornment position="end">{state.integrationData.integrationMeasurement}</InputAdornment>,
          }}
          value={state.integrationData.integrationNewUnit.unitDepth}
          onChange={(e)=>{dispatchState({ type: 'integration-data', payload: { integrationNewUnit:{...state.integrationData.integrationNewUnit, unitDepth: e.target.value }} })}}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          className={classes.buttonSpacing}
          color="primary"
          size="large"
          fullWidth
          onClick={state.integrationData.integrationIsNewHelper ? saveNewUnit() : saveUnit()}
        >Save{state.integrationData.integrationIsNewHelper ? " new " : " "}unit</Button>
      </Grid>
    </Grid>
  )
}

export default UnitsStepAdvanced
