import React from "react";

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles"
import { Grid, Card, CardContent, Typography, Fab, Tooltip } from "@material-ui/core"
import EditRounded from '@material-ui/icons/EditRounded'
import CodeRounded from '@material-ui/icons/CodeRounded'
import FileCopyRounded from '@material-ui/icons/FileCopyRounded'
import DeleteForeverRounded from '@material-ui/icons/DeleteForeverRounded'

// Other components
import classNames from "classnames"

// style
import styles from "assets/jss/material-dashboard-react/components/adminPanelsStyle"

const useStyles = makeStyles(styles)

const AdminPanels = props => {
  const classes = useStyles()
  const { theHeader, headerAction, fabCodeAction, fabCloneAction, fabEditAction, fabDeleteAction, panelTheme, children } = props

  const isDarkThemed = panelTheme === "dark" ? true : false
  return (
    <Card elevation={0} className={classNames(classes.customCard, {[classes.customCardDark]: isDarkThemed})}>
        <CardContent>
          {theHeader &&
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Grid item>
                <Typography variant="h5" component="h2" className={classes.panelHeader}>{theHeader}</Typography>
              </Grid>
              {headerAction &&
                <Grid item>
                  <Tooltip title="Edit" aria-label="Edit">
                    <Fab
                      color="primary"
                      aria-label="Edit"
                      size="medium" 
                      onClick={headerAction}
                    >
                      <EditRounded />
                  </Fab>
                </Tooltip>
                </Grid>
              }
            </Grid>
          }
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid item xs={theHeader ? 12 : false} md={theHeader ? 12 : false}>
              {children}
            </Grid>
            <Grid item>
              { fabCodeAction &&
                <Tooltip title="View integration code" aria-label="View integration code">
                  <Fab
                    color="primary"
                    aria-label="View integration code"
                    size="medium" 
                    onClick={fabCodeAction}
                  >
                    <CodeRounded />
                  </Fab>
                </Tooltip>
              }
              { fabCloneAction &&
                <Tooltip title="Clone integration" aria-label="Clone integration">
                  <Fab
                    color="primary"
                    aria-label="Clone integration"
                    size="medium" 
                    onClick={fabCloneAction}
                  >
                    <FileCopyRounded />
                  </Fab>
                </Tooltip>
              }
              { fabEditAction &&
                <Tooltip title="Edit integration" aria-label="Edit integration">
                  <Fab
                    color="primary"
                    aria-label="Edit integration"
                    size="medium" 
                    onClick={fabEditAction}
                  >
                    <EditRounded />
                  </Fab>
                </Tooltip>
              }
              { fabDeleteAction &&
                <Tooltip title="Delete integration" aria-label="Delete integration">
                  <Fab
                    color="primary"
                    aria-label="Delete integration"
                    size="medium" 
                    onClick={fabDeleteAction}
                    className={classes.deleteIconColour}
                  >
                    <DeleteForeverRounded />
                  </Fab>
                </Tooltip>
              }
            </Grid>
          </Grid>
        </CardContent>
      </Card>
  )
}

export default AdminPanels
