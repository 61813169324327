import React from "react";

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles"
import { Grid, Card, Typography, Fab, Tooltip } from "@material-ui/core"
import EditRounded from '@material-ui/icons/EditRounded'

// style
import styles from "assets/jss/material-dashboard-react/components/paymentSelectedStyle"
import iconVisa from "assets/img/icon-visa.svg"

const useStyles = makeStyles(styles)

const PaymentSelected = props => {
  const classes = useStyles()
  const { cardIcon, cardDigits, expDate, marginTopBottom, editBtn } = props

  const cardType = () => {
    let finalCard
    if(cardIcon === "visa"){
      finalCard = iconVisa
    }else if (cardIcon === "mc"){
      finalCard = iconVisa
    }else if (cardIcon === "amex"){
      finalCard = iconVisa
    }else{
      finalCard = iconVisa
    }
    return (
      finalCard
    )
  }

  return (
    <Card variant="outlined" className={classes.CustomPricingW} style={{marginTop:marginTopBottom, marginBottom:marginTopBottom}}>
      <Grid container spacing={0} direction="row" justify="space-between" alignItems="center" className={classes.planSelected}>
        <Grid item>
          <Fab
            color="primary"
            aria-label="Edit"
            size="medium"
            onClick={editBtn}
          >
            <img src={cardType()} width={35} alt="credit card icon" />
          </Fab>
          <div className={classes.cardDetails}>
            <Typography variant="subtitle2">xxxx xxxx xxxx {cardDigits}</Typography>
            <Typography variant="caption">Exp.{expDate}</Typography>
          </div>
        </Grid>
        <Grid item>
          <Tooltip title="Change credit card" aria-label="Change credit card">
            <Fab
              color="primary"
              aria-label="Edit"
              size="medium"
              onClick={editBtn}
            >
              <EditRounded />
            </Fab>
          </Tooltip>
        </Grid>
      </Grid>
    </Card>
  )
}

export default PaymentSelected
