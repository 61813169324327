import React, { useContext, useEffect } from "react"

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles"
import { Grid, TextField, Button, Typography, Select, MenuItem, InputLabel, FormControl } from "@material-ui/core"

// Core components

// Other components
import { useGlobalState } from "hooks/useGlobalState"

// Style
import styles from "assets/jss/material-dashboard-react/layouts/transactionalStyle"
import { Link } from "react-router-dom"

const useStyles = makeStyles(styles)

const PaymentStep = () => {
  const classes = useStyles()
  const dispatchState = useContext(useGlobalState.Dispatch)
  let state = useContext(useGlobalState.State)

  const countryDrowdownHandler = event => () => {
    dispatchState({ type: 'root-state', payload: { accountCurrentCountryValue: event.target.value } })
  }
  /* eslint-disable */
  useEffect(() => {
    dispatchState({ type: 'root-state', payload: { signUpStepperStep: 1 }}) // Set stepper step number 2
  }, []);
  /* eslint-enable */
  return (
    <form noValidate autoComplete="off" className={classes.standardForm}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5" component="h2">Payment details</Typography>
        </Grid>
        <Grid item xs={12}>
          Stripe component here
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" component="h2">Billing Details</Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel htmlFor="cm-signup-country-label">Country</InputLabel>
            <Select
              id="cm-signup-country"
              value={state.accountCurrentCountryValue}
              variant="outlined"
              label="Country"
              onChange={countryDrowdownHandler}
            >
              {state.accountCurrentCountryList.map((label, key) => (
                <MenuItem key={key} value={label.value}>{label.country}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <TextField id="p-signup-fname" label="First name" variant="outlined" type="text" fullWidth />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <TextField id="p-signup-lname" label="Last name" variant="outlined" type="text" fullWidth />
        </Grid>
        <Grid item xs={12}>
          <TextField id="p-signup-address" label="Address" variant="outlined" type="text" fullWidth />
        </Grid>
        <Grid item xs={12}>
          <TextField id="p-signup-company-id" label="Business ID / Taxation ID" variant="outlined" type="text" fullWidth />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2">You will be charged monthly/annually on the anniversary date of when your free trial period ends. Reminder you can cancel anytime</Typography>
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          <Button
            component={Link}
            to="/sign-up"
            variant="outlined"
            className={classes.buttonSpacing}
            color="primary"
            size="large"
            fullWidth
          >
            Back
          </Button>
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          <Button
            variant="contained"
            component={Link}
            to="/sign-up/confirmation"
            className={classes.buttonSpacing}
            color="primary"
            size="large"
            fullWidth
          >
            Next
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default PaymentStep
