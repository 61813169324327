import { primaryColour } from "assets/jss/material-dashboard-react";

const muiStepperStyle = {
  MuiStepper: {
    root: {
      "& .MuiStepConnector-line": {
        borderTopWidth: 2,
        borderColor: primaryColour[100],
      },
      "& .MuiStepLabel-label": {
        color: primaryColour[100],
        marginTop: 7,
        fontWeight: 500,
      },
      "& .MuiStepIcon-text": {
        fontWeight: 500,
      },
      "& .MuiStepIcon-root": {
        color: primaryColour[100],
      },
      "& .MuiStepConnector-active .MuiStepConnector-line, & .MuiStepConnector-completed .MuiStepConnector-line": {
        borderColor: primaryColour[900],
      },
      "& .MuiStepLabel-label.MuiStepLabel-active, & .MuiStepIcon-root.MuiStepIcon-active, & .MuiStepIcon-completed, & .MuiStepLabel-label.MuiStepLabel-completed": {
        color: primaryColour[900],
      },
    }
  },
};

export default muiStepperStyle;
