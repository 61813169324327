import React from 'react'
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import { BlockPicker } from 'react-color'

const styles = {
  picker:{
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
    "& + .block-picker": {
      margin:"0 auto !important",
      left:"0 !important",
      right:"0 !important",
      boxShadow: "0px 1px 10px rgba(0, 0, 0, 0.25) !important"
    },
    "& + .block-picker div:nth-child(2)": {
      height:"50px !important"
    }
  },
  pickerWrapper: {
    position: "realtive"
  }
}
const useStyles = makeStyles(styles);

const ColourPickerDialog = ({
  value,
  onClick,
  onChange
}) => {
  const classes = useStyles();
  return(
    <div style={{ position: 'relative' }}>
      <div style={{ position: 'absolute', zIndex: '2', width:"100%" }}>
        <div
          className={classes.picker}
          onClick={onClick}
        />
        <BlockPicker
          color={value}
          colors={['#D9E3F0', '#F47373', '#697689', '#37D67A', '#2CCCE4', '#555555', '#dce775', '#ff8a65', '#ba68c8','#FFCC00']}
          onChange={
            (color)=>{
              onChange(color.hex)
            }
          }
        />
      </div>
    </div>
  )
}

export default ColourPickerDialog