import React from "react"

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles"
import { Typography, Grid, TextField, Button} from "@material-ui/core"

// core components

// Other components

// Style
import styles from "assets/jss/material-dashboard-react/layouts/transactionalStyle"

const useStyles = makeStyles(styles)

const ContactForm = () => {
  const classes = useStyles()

  return (
    <form noValidate autoComplete="off" className={classes.standardForm}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="body1">Don’t be a stranger. Fill out the form and we’ll be in touch as soon as possible.</Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField id="p-contact-name" label="Name" variant="outlined" type="text" fullWidth />
        </Grid>
        <Grid item xs={12}>
          <TextField id="p-contact-email" label="Email" variant="outlined" type="email" fullWidth />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="p-contact-message"
            label="Message"
            multiline
            rows="5"
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" className={classes.buttonSpacing} color="primary" size="large" fullWidth>Send it!</Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default ContactForm
