import { whiteColor } from "assets/jss/material-dashboard-react";
import { primaryColour } from "assets/jss/material-dashboard-react";
import { grayColor } from "assets/jss/material-dashboard-react";

const transactionStyle = theme => ({
  pWrapper: {
    minHeight: "100vh",
    padding: theme.spacing(2),
  },
  pContainer: {
    backgroundColor: whiteColor,
    borderRadius: 30,
    position: "relative"
  },
  cFormContaner: {
    margin: "0 auto",
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3)
  },
  cWidthLimiter: {
    maxWidth: 535
  },
  headerOneLine: {
    textAlign: "center",
    lineHeight: "130%",
    "&::after": {
      content: `" "`,
      display: "block",
      width: 30,
      height: 2,
      backgroundColor: primaryColour[500],
      margin: "12px auto 0 auto"
    }
  },
  headerTwoLine: {
    lineHeight: 1.2,
    "&::after": {
      content: `" "`,
      display: "block",
      width: "100%",
      height: 1,
      backgroundColor: primaryColour[50],
      margin: "12px auto 0 auto"
    }
  },
  standardForm: {
    margin: "40px 0",
  },
  validationCodeInput: {
    "& .MuiInputBase-input": {
      fontSize: "2.5rem",
      textAlign: "center",
      maxWidth: 50
    },
  },
  buttonSpacing: {
    marginTop: 10,
    marginBottom: 10
  },
  mainLogo: {
    margin: 30
  },
  subTitleTwo: {
    color: primaryColour[900],
    lineHeight: "130%",
    marginTop: 30,
    marginBottom: 30
  },
  dataPanel: {
    borderRadius: 15,
    padding: 25,
    "& ul li": {
      padding: 0,
    },
    "& ul li > div": {
      margin: 0,
      lineHeight: 1,
      color: grayColor[7]
    },
  },
  adminTHeader: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    minHeight: 88
  },
  textMarginBottom: {
    marginBottom: 20
  }
});

export default transactionStyle;
