import React, { cloneElement } from 'react'
import { useGlobalState } from 'hooks/useGlobalState'

const providers = [<useGlobalState.Provider />]

const Dashboard = ({ children: initial }) =>
  providers.reduce(
    (children, parent) => cloneElement(parent, { children }),
    initial
  )

export { Dashboard, useGlobalState }