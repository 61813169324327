/*!

=========================================================
* Material Dashboard React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// @material-ui/icons
import Apps from "@material-ui/icons/AppsRounded"
import Person from "@material-ui/icons/PersonRounded"
import ContactSupport from "@material-ui/icons/ContactSupportRounded"

// views
import SignUp from "views/SignUp/SignUp"
import Login from "views/Login/Login"
import Pricing from "views/Pricing/Pricing"
import ContactUs from "views/ContactUs/ContactUs"
import PassRecovery from "views/PassRecovery/PassRecovery"
import AdminMyCalcumate from "views/AdminMyCalcumate/AdminMyCalcumate"
import AdminMyAccount from "views/AdminMyAccount/AdminMyAccount"
import AdminSupport from "views/AdminSupport/AdminSupport"
import AdminEditAccount from "views/AdminMyAccount/AdminEditAccount"
import AdminMySubscription from "views/AdminMySubscription/AdminMySubscription"
import AdminMySubscriptionUpdate from "views/AdminMySubscription/AdminMySubscriptionUpdate"
import AdminMySubscriptionConfirmed from "views/AdminMySubscription/AdminMySubscriptionConfirmed"
import AdminMySubscriptionCancel from "views/AdminMySubscription/AdminMySubscriptionCancel"
import AdminMySubscriptionCancelled from "views/AdminMySubscription/AdminMySubscriptionCancelled"
import AdminPaymentMethod from "views/AdminPaymentMethod/AdminPaymentMethod"
import IntegrationCreation from "views/IntegrationCreation/IntegrationCreation"
import TermsOfService from "views/TermsOfService/TermsOfService"

// layouts
import Admin from 'layouts/Admin'
import Transactional from 'layouts/Transactional'
import Integration from 'layouts/Integration'

const CMRoutes = [
  {
    path: "/terms-of-service",
    name: "Terms of service",
    icon: null,
    component: TermsOfService,
    layout: Transactional,
    inAdminNav: false
  },
  {
    path: "/login",
    name: "Login",
    icon: null,
    component: Login,
    layout: Transactional,
    inAdminNav: false
  },
  {
    path: "/password-request",
    name: "Password recovery",
    icon: null,
    component: PassRecovery,
    layout: Transactional,
    inAdminNav: false
  },
  {
    path: "/password-request/requested",
    name: "Password recovery requested",
    icon: null,
    component: PassRecovery,
    layout: Transactional,
    inAdminNav: false
  },
  {
    path: "/password-request/reset",
    name: "Password reset",
    icon: null,
    component: PassRecovery,
    layout: Transactional,
    inAdminNav: false
  },
  {
    path: "/contact-us",
    name: "Contact us",
    icon: null,
    component: ContactUs,
    layout: Transactional,
    inAdminNav: false
  },
  {
    path: "/pricing",
    name: "Pricing",
    icon: null,
    component: Pricing,
    layout: Transactional,
    inAdminNav: false
  },
  {
    path: "/sign-up",
    name: "Sign up",
    icon: null,
    component: SignUp,
    layout: Transactional,
    inAdminNav: false
  },
  {
    path: "/sign-up/payment",
    name: "Sign up - Payment",
    icon: null,
    component: SignUp,
    layout: Transactional,
    inAdminNav: false
  },
  {
    path: "/sign-up/confirmation",
    name: "Sign up - Confirmation",
    icon: null,
    component: SignUp,
    layout: Transactional,
    inAdminNav: false
  },
  {
    path: "/admin",
    name: "My Calcumate",
    icon: null,
    component: AdminMyCalcumate,
    layout: Admin,
    inAdminNav: false
  },
  {
    path: "/admin/my-calcumate",
    name: "My Calcumate",
    icon: Apps,
    component: AdminMyCalcumate,
    layout: Admin,
    inAdminNav: true
  },
  {
    path: "/admin/my-calcumate/new",
    name: "New integration",
    icon: null,
    component: IntegrationCreation,
    layout: Integration,
    inAdminNav: false
  },
  {
    path: "/admin/my-account",
    name: "My account",
    icon: Person,
    component: AdminMyAccount,
    layout: Admin,
    inAdminNav: true
  },
  {
    path: "/admin/my-account/edit",
    name: "Edit account",
    icon: null,
    component: AdminEditAccount,
    layout: Transactional,
    inAdminNav: false
  },
  {
    path: "/admin/my-account/my-subscription",
    name: "My subscription",
    icon: null,
    component: AdminMySubscription,
    layout: Transactional,
    inAdminNav: false
  },
  {
    path: "/admin/my-account/my-subscription/modify",
    name: "My subscription",
    icon: null,
    component: AdminMySubscriptionUpdate,
    layout: Transactional,
    inAdminNav: false
  },
  {
    path: "/admin/my-account/my-subscription/confirmed",
    name: "My subscription",
    icon: null,
    component: AdminMySubscriptionConfirmed,
    layout: Transactional,
    inAdminNav: false
  },
  {
    path: "/admin/my-account/my-subscription/cancel",
    name: "My subscription",
    icon: null,
    component: AdminMySubscriptionCancel,
    layout: Transactional,
    inAdminNav: false
  },
  {
    path: "/admin/my-account/my-subscription/cancelled",
    name: "My subscription",
    icon: null,
    component: AdminMySubscriptionCancelled,
    layout: Transactional,
    inAdminNav: false
  },
  {
    path: "/admin/my-account/payment-method",
    name: "Payment method",
    icon: null,
    component: AdminPaymentMethod,
    layout: Transactional,
    inAdminNav: false
  },
  {
    path: "/admin/support",
    name: "Support",
    icon: ContactSupport,
    component: AdminSupport,
    layout: Transactional,
    inAdminNav: true
  }
]

export default CMRoutes;