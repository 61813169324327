import React, { useContext, useEffect } from "react"

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles"
import { Grid, Typography, Divider, FormControl, InputLabel, Select, MenuItem } from "@material-ui/core"
// import CloudUploadRounded from "@material-ui/icons/CloudUploadRounded"

// Core components
import { useGlobalState } from "hooks/useGlobalState"
// import {useDropzone} from 'react-dropzone'

// Other components
import ColourPickerField from "components/ColourPickerField/ColourPickerField"

// Style
import styles from "assets/jss/material-dashboard-react/layouts/integrationStyle"

const useStyles = makeStyles(styles)

const DesignStep = props => {
  const classes = useStyles();
  const dispatchState = useContext(useGlobalState.Dispatch)
  const state = useContext(useGlobalState.State)
  const { theRef } = props

  /* eslint-disable */
  useEffect(() => {
    dispatchState({ type: 'integration-data', payload: { integrationStepperStep: 4 }}) // Set stepper step number 5
    theRef.current.scrollTop = 0 //scrollTop back to 0 when changing views
  }, []);
  /* eslint-enable */

  // function MyDropzone() {
  //   const onDrop = React.useCallback(acceptedFiles => {
  //     // Do something with the files
  //   }, [])
  //   const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})
  
  //   return (
  //     <div {...getRootProps()}>
  //       <input {...getInputProps()} />
  //       {
  //         isDragActive ? (
  //           <div className={classes.ddArea}>
  //               <CloudUploadRounded />
  //               <Typography variant="subtitle2">Drop the files here ...</Typography>
  //           </div>
  //           ) : (
  //             <div className={classes.ddArea}>
  //               <CloudUploadRounded />
  //               <Typography variant="subtitle2">Click to upload logo or Drag 'n' drop the file here</Typography>
  //           </div>
  //         )
  //       }
  //     </div>
  //   )
  // }
  return (
    <form noValidate autoComplete="off" className={classes.standardForm} id="custom-form">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5" component="h1">Design</Typography>
        </Grid>
        {/* <Grid item xs={12}>
          <Divider className={classes.customDividerOne} />
          <Typography variant="subtitle1" component="h2" className={classes.subHeadOne}>Branding</Typography>
          <FormControl fullWidth>
            <InputLabel htmlFor="p-integration-language">Font Family</InputLabel>
            <Select
              id="p-integration-language"
              value={state.integrationData.integrationActiveFont}
              variant="outlined"
              label="Select language"
              onChange={(e)=>{dispatchState({ type: 'integration-data', payload: { integrationActiveFont: e.target.value } })}}
            >
              {state.integrationData.integrationFontFamily.map((label, key) => (
                <MenuItem key={key} value={label}>{label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid> */}
        <Grid item xs={12}>
          <Divider className={classes.customDividerOne} />
          <Typography variant="subtitle1" component="h2" className={classes.subHeadOne}>Colour scheme</Typography>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <ColourPickerField
                value={state.integrationData.integrationPrimaryColour}
                onChange={(color)=>{dispatchState({ type: 'integration-data', payload: { integrationPrimaryColour: color } })}}
                id="p-integration-primary-color"
                label="Primary colour"
              />
            </Grid>
            <Grid item xs={6}>
              <ColourPickerField
                value={state.integrationData.integrationSecondaryColour}
                onChange={(color)=>{dispatchState({ type: 'integration-data', payload: { integrationSecondaryColour: color } })}}
                id="p-integration-secondary-color"
                label="Secondary colour"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel htmlFor="p-integration-language">Background type</InputLabel>
                <Select
                  id="p-integration-language"
                  value={state.integrationData.integrationActiveBgStyle}
                  variant="outlined"
                  label="Select language"
                  onChange={(e)=>{dispatchState({ type: 'integration-data', payload: { integrationActiveBgStyle: e.target.value } })}}
                >
                    <MenuItem key={0} value="solid">Solid</MenuItem>
                    <MenuItem key={1} value="gradient">Gradient</MenuItem> 
                </Select>
              </FormControl>
            </Grid>
            {state.integrationData.integrationActiveBgStyle === "solid" &&
              <Grid item xs={12}>
                <ColourPickerField
                  value={state.integrationData.integrationGradientOne}
                  onChange={(color)=>{dispatchState({ type: 'integration-data', payload: { integrationGradientOne: color } })}}
                  id="p-integration-gradient-one"
                  label="Background colour"
                />
              </Grid>
            }
            {state.integrationData.integrationActiveBgStyle === "gradient" &&
              <Grid item xs={6}>
                <ColourPickerField
                  value={state.integrationData.integrationGradientOne}
                  onChange={(color)=>{dispatchState({ type: 'integration-data', payload: { integrationGradientOne: color } })}}
                  id="p-integration-gradient-one"
                  label="Gradient top colour"
                />
              </Grid>
            }
            {state.integrationData.integrationActiveBgStyle === "gradient" &&
              <Grid item xs={6}>
                <ColourPickerField
                  value={state.integrationData.integrationGradientTwo}
                  onChange={(color)=>{dispatchState({ type: 'integration-data', payload: { integrationGradientTwo: color } })}}
                  id="p-integration-gradient-twto"
                  label="Gradient bottom colour"
                />
              </Grid>
            }
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider className={classes.customDividerOne} />
          <Typography variant="subtitle1" component="h2" className={classes.subHeadOne}>Buttons</Typography>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <ColourPickerField
                value={state.integrationData.integrationButtonColour}
                onChange={(color)=>{dispatchState({ type: 'integration-data', payload: { integrationButtonColour: color } })}}
                id="p-integration-button-color"
                label="Button colour"
              />
            </Grid>
            <Grid item xs={6}>
              <ColourPickerField
                value={state.integrationData.integrationButtonTextColour}
                onChange={(color)=>{dispatchState({ type: 'integration-data', payload: { integrationButtonTextColour: color } })}}
                id="p-integration-button-txt-color"
                label="Button text colour"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  )
}

export default DesignStep
