import React from "react"

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles"
import { Grid, Typography, Button } from "@material-ui/core"

// Core components
// import { Link } from "react-router-dom"

// Other components
import PasswordInput from "components/PasswordInput/PasswordInput"

// Style
import styles from "assets/jss/material-dashboard-react/layouts/transactionalStyle"
const useStyles = makeStyles(styles)

const RecoveryReset = () => {
  const classes = useStyles()
  return (
    <form noValidate autoComplete="off" className={classes.standardForm}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="body1" align="center">Enter your new password in order to reset.</Typography>
        </Grid>
        <Grid item xs={12}>
          <PasswordInput />
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" className={classes.buttonSpacing} color="primary" size="large" fullWidth>Set new password</Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default RecoveryReset
