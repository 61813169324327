import React from "react"

// @material-ui/core components
import { Grid } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

// Core components
import { Link } from "react-router-dom"

// Other components

// Styles
import styles from "assets/jss/material-dashboard-react/layouts/transactionalStyle"
import elipseShine from "assets/img/cm-logotype.svg"

const useStyles = makeStyles(styles);

const Transactional = props => {
  const classes = useStyles()
  let currentPath = props.children.props.location.pathname
  const { children } =  props

  return (
    <Grid container spacing={0} className={classes.pWrapper} alignItems="stretch">
      <Grid item xs={12} sm={12} className={classes.pContainer}>
        {!currentPath.includes("admin") &&
          <Link to="/">
            <img src={elipseShine} className={classes.mainLogo} alt="Calcumate"></img>
          </Link>
        }
        {children}
      </Grid>
    </Grid>
  );
}

export default Transactional
