import React, { useContext } from "react"

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles"
import { Typography, Grid, Switch } from "@material-ui/core"

// core components
import classNames from "classnames"

// Other components
import { useGlobalState } from "hooks/useGlobalState"

// Style
import styles from "assets/jss/material-dashboard-react/components/customPricingStyle"

const useStyles = makeStyles(styles)

const CustomPricingSwitch = () => {
  const classes = useStyles()
  const dispatchState = useContext(useGlobalState.Dispatch)
  const state = useContext(useGlobalState.State)

  const updateStatus = () => dispatchState({ type: 'root-state', payload: { cmPlanPayRec: (state.cmPlanPayRec ? false : true) } })

  return (
    <Grid container spacing={1} direction="row" justify="center" alignItems="center">
      <Grid item>
        <Typography
          variant="subtitle1"
          className={classNames(classes.PricingWidgetTextColor, {[classes.PricingWidgetTextColorActive]: !state.cmPlanPayRec})}
          onClick={updateStatus}>
            Monthly
          </Typography>
      </Grid>
      <Grid item>
        <Switch color="primary" value="Yearly" className={classes.pricingSwitch} checked={state.cmPlanPayRec} onClick={updateStatus} />
      </Grid>
      <Grid item>
        <Typography
          variant="subtitle1"
          className={classNames(classes.PricingWidgetTextColor, {[classes.PricingWidgetTextColorActive]: state.cmPlanPayRec})}
          onClick={updateStatus}>
            Yearly
          </Typography>
      </Grid>
    </Grid>
  )
}

export default CustomPricingSwitch
