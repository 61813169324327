/*eslint-disable*/
import React from "react"

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import { Grid, TextField, Button, Typography } from "@material-ui/core"

// core components
import { Link } from "react-router-dom"

// Other components
import PasswordInput from "components/PasswordInput/PasswordInput"
import AdminTransactionalHeader from "components/AdminTransactionalHeader/AdminTransactionalHeader"

// Style
import styles from "assets/jss/material-dashboard-react/layouts/transactionalStyle"

const useStyles = makeStyles(styles)

const AdminEditAccount = props => {
  const classes = useStyles();
  
  return (
    <Grid container spacing={0} justify="center" className={classes.cFormContaner}>
      <Grid item xs={12} sm={12} md={12}>
        <AdminTransactionalHeader
          backBtn={false}
          closeBtn={() => {props.history.push("/admin/my-account")}}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} className={classes.cWidthLimiter}>
        <Typography variant="overline">Account details</Typography>
        <Typography variant="h1" className={classes.headerTwoLine}>user@email.com</Typography>
        <form noValidate autoComplete="off" className={classes.standardForm}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6}>
              <TextField id="p-edit-fname" label="First name" variant="outlined" type="text" fullWidth />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <TextField id="p-edit-lname" label="Last name" variant="outlined" type="text" fullWidth />
            </Grid>
            <Grid item xs={12}>
              <TextField id="p-edit-company" label="Company name (Optional)" value="The company name" variant="outlined" type="text" disabled fullWidth />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" component="h2">Change password</Typography>
            </Grid>
            <Grid item xs={12}>
              <PasswordInput
                uniqueID="p-edit-password"
                uniqueLabel="Password"
              />
            </Grid>
            <Grid item xs={12}>
              <PasswordInput
                uniqueID="p-edit-confirm-password"
                uniqueLabel="Confirm"
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                component={Link}
                to={'/admin/my-account'}
                className={classes.buttonSpacing}
                color="primary"
                size="large"
                fullWidth
              >Save admin details</Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
}

export default AdminEditAccount
