import {
  drawerWidth,
  transition,
  boxShadow,
  defaultFont,
  whiteColor,
  hexToRgb
} from "assets/jss/material-dashboard-react.js";
import { primaryColour } from "assets/jss/material-dashboard-react";

const adminSideBarStyle = theme => ({
  drawerPaper: {
    border: "none",
    position: "fixed",
    top: "0",
    bottom: "0",
    left: "0",
    zIndex: "1",
    backgroundColor: primaryColour[800],
    width: drawerWidth,
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      position: "fixed",
      height: "100%",
      borderRadius: '0 30px 30px 0',
    },
    [theme.breakpoints.down("sm")]: {
      width: drawerWidth,
      ...boxShadow,
      position: "fixed",
      display: "block",
      top: "0",
      height: "100vh",
      right: "0",
      left: "auto",
      zIndex: "1032",
      visibility: "visible",
      overflowY: "visible",
      borderTop: "none",
      textAlign: "left",
      paddingRight: "0px",
      paddingLeft: "0",
      transform: `translate3d(${drawerWidth}px, 0, 0)`,
      ...transition
    }
  },
  logo: {
    position: "relative",
    padding: "7px 15px 0 15px",
    zIndex: "4"
  },
  logoLink: {
    ...defaultFont,
  },
  logoImage: {
    width: "230px",
    display: "block",
  },
  img: {
    width: "230px",
    verticalAlign: "middle",
    border: "0"
  },
  list: {
    marginTop: "30px",
    paddingLeft: "0",
    paddingTop: "0",
    paddingBottom: "0",
    marginBottom: "0",
    listStyle: "none",
    position: "unset"
  },
  item: {
    position: "relative",
    display: "block",
    textDecoration: "none",
    "&:hover,&:focus,&:visited,&": {
      color: primaryColour[900],
    }
  },
  itemLink: {
    width: "auto",
    transition: "none",
    margin: "10px 0 0 15px",
    borderRadius: "50px 0 0 50px",
    position: "relative",
    display: "block",
    padding: "20px 25px",
    backgroundColor: "transparent",
    ...defaultFont,
  },
  itemIcon: {
    width: "24px",
    height: "30px",
    fontSize: "24px",
    lineHeight: "30px",
    float: "left",
    marginRight: "15px",
    textAlign: "center",
    verticalAlign: "middle",
    color: "rgba(" + hexToRgb(whiteColor) + ", 0.8)"
  },
  itemText: {
    ...defaultFont,
    margin: "0",
    lineHeight: "30px",
    fontSize: "14px",
    textTransform: "uppercase",
    fontWeight: 500,
    color: whiteColor,
  },
  whiteFont: {
    color: primaryColour[900],
    "&::before": {
      content: `""`,
      position: "absolute",
      backgroundColor: "transparent",
      top: "-60px",
      right: "0",
      height: "60px",
      width: "30px",
      borderRadius: "0 0 30px 0",
      boxShadow: "0 30px 0 " + primaryColour[50],
      zIndex:"5",
    },
    "&::after": {
      content: `""`,
      position: "absolute",
      backgroundColor: "transparent",
      bottom: "-60px",
      right: "0",
      height: "60px",
      width: "30px",
      borderRadius: "0 30px 0 0",
      boxShadow: "0 -30px 0 " + primaryColour[50],
    },
  },
  blue: {
    backgroundColor: primaryColour[50],
    color: primaryColour[900],
    boxShadow: "none",
    "&:hover,&:focus": {
      backgroundColor: primaryColour[50],
      boxShadow: "none",
    },
    "& .MuiTouchRipple-root": {
      display: "none",
    },
  },
  sidebarWrapper: {
    position: "relative",
    height: "calc(100vh - 75px)",
    overflow: "auto",
    width: "260px",
    zIndex: "4",
    overflowScrolling: "touch"
  },
  isSeparator: {
    position: "relative",
    paddingTop: 15,
    "& > div": {
      marginTop: 15,
    },
    "&::before": {
      content: `" "`,
      display: "block",
      position: "absolute",
      width: "70%",
      height: 1,
      backgroundColor: primaryColour[700],
      left: 0,
      right: 0,
      margin: "0 auto"
    }
  }
});

export default adminSideBarStyle
