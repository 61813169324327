import React from "react";

// core components
import classNames from 'classnames'

// other components
import { Typography, makeStyles, Grid } from "@material-ui/core";

// styles
import styles from "assets/jss/material-dashboard-react/components/adminHeadingStyle"

const useStyles = makeStyles(styles)

const AdminHeading = props => {
  const classes = useStyles();
  const { heading, overline, results, isMain} = props
  return (
    <Grid 
      container
      direction="row"
      justify="space-between"
      alignItems="center"
      className={classNames(classes.headerMargin, {[classes.extraMargin]: !isMain})}
    >
      <Grid item>
        <Typography variant="overline">{overline}</Typography>
        <Typography variant="h1">{heading}</Typography>
      </Grid>
      <Grid item>
        <Typography variant="overline" className={classes.results}>{results}</Typography>
      </Grid>
    </Grid>
  );
}

export default AdminHeading
