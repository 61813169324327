import React, { useContext } from "react"

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles"
import { Grid } from "@material-ui/core"

// core components
import { useGlobalState } from "hooks/useGlobalState"

// Other components
import CustomPricingPanel from "components/CustomPricing/CustomPricingPanel/CustomPricingPanel"
import CustomPricingSwitch from "./CustomPricingPanel/CustomPricingSwitch"

// Style
import styles from "assets/jss/material-dashboard-react/components/customPricingStyle"

const useStyles = makeStyles(styles)

const CustomPricingPanels = () => {
  const classes = useStyles();
  const state = useContext(useGlobalState.State)
  
  return (
    <div>
      <CustomPricingSwitch />
      <Grid container spacing={2} direction="row" justify="center" alignItems="center" className={classes.pricingPanels}>
        {state.calcumatePlans.map((value, index) => {
          return (
            <Grid item key={index}>
              <CustomPricingPanel
                index={index}
                {...value}
              />
            </Grid>
          )
        })}
      </Grid>
    </div>
  );
}

export default CustomPricingPanels
