/*eslint-disable*/
import React, { useContext, useState } from "react"

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import { Grid, TextField, Button, Typography, Divider, FormControl, InputLabel, Select, MenuItem } from "@material-ui/core"

// core components
import { Link } from "react-router-dom"
import { useGlobalState } from "hooks/useGlobalState"

// Other components
import PasswordInput from "components/PasswordInput/PasswordInput"
import AdminTransactionalHeader from "components/AdminTransactionalHeader/AdminTransactionalHeader"
import PaymentSelected from "components/PaymentSelected/PaymentSelected"

// Style
import styles from "assets/jss/material-dashboard-react/layouts/transactionalStyle"

const useStyles = makeStyles(styles)

const AdminMySubscriptionUpdate = props => {
  const classes = useStyles()
  const dispatchState = useContext(useGlobalState.Dispatch)
  let state = useContext(useGlobalState.State)

  //Local state
  let [values, setValues] = useState({
    paymentMod: false
  })

  const setToModCard = mode => () => {
    setValues({ paymentMod: mode })
  }

  const countryDrowdownHandler = event => () => {
    dispatchState({ type: 'root-state', payload: { accountCurrentCountryValue: event.target.value } })
  }

  const triggerButtons = () => {
    if(values.paymentMod) {
      return (
        <form noValidate autoComplete="off" className={classes.standardForm}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h5" component="h2">New payment details</Typography>
            </Grid>
            <Grid item xs={12}>
              Stripe component here
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" component="h2">New billing details</Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel htmlFor="cm-signup-country-label">Country</InputLabel>
                <Select
                  id="cm-signup-country"
                  value={state.accountCurrentCountryValue}
                  variant="outlined"
                  label="Country"
                  onChange={countryDrowdownHandler}
                >
                  {state.accountCurrentCountryList.map((label, key) => (
                    <MenuItem key={key} value={label.value}>{label.country}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <TextField id="p-signup-fname" label="First name" variant="outlined" type="text" fullWidth />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <TextField id="p-signup-lname" label="Last name" variant="outlined" type="text" fullWidth />
            </Grid>
            <Grid item xs={12}>
              <TextField id="p-signup-address" label="Address" variant="outlined" type="text" fullWidth />
            </Grid>
            <Grid item xs={12}>
              <TextField id="p-signup-company" label="Company name (Optional)" variant="outlined" type="text" fullWidth />
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
              <Button
                onClick={setToModCard(false)}
                variant="outlined"
                className={classes.buttonSpacing}
                color="primary"
                size="large"
                fullWidth
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
              <Button
                onClick={setToModCard(false)}
                variant="contained"
                className={classes.buttonSpacing}
                color="primary"
                size="large"
                fullWidth
              >
                Save details
              </Button>
            </Grid>
          </Grid>
        </form>
      )
    }else{
      return (
        <div>
          <PaymentSelected
            cardIcon="visa" // visa, mc, amex
            cardDigits="1234"
            expDate="12/12"
            marginTopBottom={20}
            editBtn={setToModCard(true)}
          />
          <Divider style={{marginTop:30, marginBottom:20}} />
          <Grid container spacing={0} direction="row" justify="space-between" alignItems="center" className={classes.planSelected}>
            <Grid item><Typography variant="h6">Amount payable</Typography></Grid>
            <Grid item><Typography variant="h6">USD$1,448</Typography></Grid>
          </Grid>
          <Grid container spacing={0} className={classes.planSelected}>
            <Grid item sm={12} md={12} lg={12}>
            <Button
                variant="contained"
                component={Link}
                to={'/admin/my-account/my-subscription/confirmed'}
                className={classes.buttonSpacing}
                color="primary"
                size="large"
                style={{marginTop:30}}
                fullWidth>
                Upgrade my plan
              </Button>
            </Grid>
          </Grid>
        </div>
      )
    }
  }
  
  return (
    <Grid container spacing={0} justify="center" className={classes.cFormContaner}>
      <Grid item xs={12} sm={12} md={12}>
        <AdminTransactionalHeader
          backBtn={() => {props.history.push("/admin/my-account/my-subscription")}}
          closeBtn={false}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} className={classes.cWidthLimiter}>
        <Typography variant="overline">Subscription purchase</Typography>
        <Typography variant="h1" className={classes.headerTwoLine}>{state.currentPlan} ${state.currentPlanPrice}{state.currentPlanPriceRec}</Typography>

        {triggerButtons()}

      </Grid>
    </Grid>
  );
}

export default AdminMySubscriptionUpdate
