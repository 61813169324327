import React, { useEffect, useState } from "react"

// @material-ui/core components
import { Grid, Typography, Button} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

//Core components
import { Link } from "react-router-dom"
import classNames from "classnames"
import AnchorLink from "react-anchor-link-smooth-scroll"

//Other Components

//Style
import elipseShine from "assets/img/cm-logotype.svg"
import styles from "assets/jss/material-dashboard-react/components/homeHeaderStyle"

const useStyles = makeStyles(styles);

const HomeHeader = () => {
  const classes = useStyles();
  console.log(window)
  const [headerOn, setHeaderOn] = useState(false) //local state

  const checkScrollingState = () => {
    window.pageYOffset > 150 ? setHeaderOn(true) : setHeaderOn(false)
  }

  useEffect(() =>{
    window.addEventListener('scroll', checkScrollingState);
  })

  return (
    <div className={classNames(classes.headerBackground, {[classes.onScrolling]: headerOn})}>
      <Grid container className={classes.headerContainer} spacing={0} direction="row" justify="space-between" alignItems="center">
        <Grid item className={classes.headerItemLogo}>
        <AnchorLink href='#main-section'><img src={elipseShine} className={classNames({[classes.whiteLogo]: !headerOn})} alt="Calcumate"/></AnchorLink>
        </Grid>
        <Grid item className={classes.headerItemLogo}>
          <AnchorLink offset="100" href='#features-section' className={classNames(classes.standardLink,{[classes.whiteLink]: !headerOn})}>Features</AnchorLink>
          <AnchorLink offset="100" href='#demo-section' className={classNames(classes.ml3, classes.standardLink,{[classes.whiteLink]: !headerOn})}>Demo</AnchorLink>
          <Typography variant="subtitle2" component={Link} to="/pricing" className={classNames(classes.ml3, classes.standardLink,{[classes.whiteLink]: !headerOn})}>Pricing</Typography>
          <Typography variant="subtitle2" component={Link} to="/contact-us"  className={classNames(classes.ml3, classes.standardLink,{[classes.whiteLink]: !headerOn})}>Contact</Typography>
          <span className={classNames(classes.navSeparator, {[classes.whiteLink]: !headerOn})}>|</span>
          <Button size="small" variant="outlined" color={headerOn ? "primary" : "default"} component={Link} to="/login">Login</Button>
          <Button size="small" variant="contained" color={headerOn ? "primary" : "default"} component={Link} to="/pricing" className={classes.ml2}>Sign up free</Button>
        </Grid>
      </Grid>
    </div>
  );
}

export default HomeHeader
