import { primaryColour } from "assets/jss/material-dashboard-react"
import muiFormStyle from "./components/MuiOverrides/muiFormStyle"
import muiFabStyle from "./components/MuiOverrides/muiFabStyle"
import muiButtonStyle from "./components/MuiOverrides/muiButtonStyle"
import muiPaperStyle from "./components/MuiOverrides/muiPaperStyle"
import muiStepperStyle from "./components/MuiOverrides/muiStepperStyle"
import muiThemeTypography from "./components/MuiOverrides/muiThemeTypography"
import muiThemeProps from "./components/MuiOverrides/muiThemeProps"
import muiTooltipStyle from "./components/MuiOverrides/muiTooltipStyle"
import muiBackdropStyle from "./components/MuiOverrides/muiBackdropStyle"


const themeStyles = {
  overrides: {
    ...muiFormStyle,
    ...muiFabStyle,
    ...muiButtonStyle,
    ...muiPaperStyle,
    ...muiTooltipStyle,
    ...muiStepperStyle,
    ...muiBackdropStyle,
    MuiLink: {
      root: {
        "&:hover": {
          color: primaryColour[600]
        }
      }
    }
  },
  props: {
    ...muiThemeProps
  },
  ...muiThemeTypography
}

export default themeStyles