import React, { useContext, useState } from "react"

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles"
import { Stepper, Step, StepLabel, Grid, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@material-ui/core"

// Core components
import classNames from 'classnames'
import { useGlobalState } from "hooks/useGlobalState"
import { Link } from "react-router-dom"

// Other components

// Style
import styles from "assets/jss/material-dashboard-react/layouts/integrationStyle"

const useStyles = makeStyles(styles)

const IntegrationStepper = props => {
  const classes = useStyles()
  const state = useContext(useGlobalState.State)
  const { urlParam } = props
  const [openDialog, setOpenDialog] = useState(false) //local state

  const prevButton = () => {
    switch(urlParam) {
      case "?step=units":
        return (
          <Button variant="contained" component={Link} to={'/admin/my-calcumate/new?step=options'} size="large">Back</Button>
        )
      case "?step=categories":
        return (
          <Button variant="contained" component={Link} to={'/admin/my-calcumate/new?step=units'} size="large">Back</Button>
        )
      case "?step=presets":
        return (
          <Button variant="contained" component={Link} to={'/admin/my-calcumate/new?step=categories'} size="large">Back</Button>
        )
      case "?step=design":
        return (
          <Button variant="contained" component={Link} to={'/admin/my-calcumate/new?step=presets'} size="large">Back</Button>
        )
      case "?step=confirm":
        return (
          <Button variant="contained" component={Link} to={'/admin/my-calcumate/new?step=design'} size="large">Back</Button>
        )
      case "?step=confirmed":
        return (
          <Button variant="contained" component={Link} to={'/admin/my-calcumate/new'} size="large">Edit</Button>
        )
      default:
        return (
          <Button variant="contained" size="large" onClick={()=>{setOpenDialog(true)}}>Back to Dashboard</Button>
        )
    }
  }

  const nextButton = () => {
    switch(urlParam) {
      case "?step=units":
        return (
          <Button variant="contained" component={Link} to={'/admin/my-calcumate/new?step=categories'} size="large">Next</Button>
        )
      case "?step=categories":
        return (
          <Button variant="contained" component={Link} to={'/admin/my-calcumate/new?step=presets'} size="large">Next</Button>
        )
      case "?step=presets":
        return (
          <Button variant="contained" component={Link} to={'/admin/my-calcumate/new?step=design'} size="large">Next</Button>
        )
      case "?step=design":
        return (
          <Button variant="contained" component={Link} to={'/admin/my-calcumate/new?step=confirm'} size="large">Next</Button>
        )
      case "?step=confirm":
        return (
          <Button variant="contained" component={Link} to={'/admin/my-calcumate/new?step=confirmed'} size="large">Publish</Button>
        )
      case "?step=confirmed":
        return (
          <Button variant="contained" component={Link} to={'/admin/my-calcumate/'} size="large">Done</Button>
        )
      default:
        return (
          <Button variant="contained" component={Link} to={'/admin/my-calcumate/new?step=units'} size="large">Next</Button>
        )
    }
  }

  return (
    <div className={classNames(classes.stepperIndex, {[classes.stepperHidden]: state.integrationData.integrationPanelsFS})}>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Grid item style={{flex:"0 0 300px"}}>
          {prevButton()}
        </Grid>
        <Grid item style={{flex:1}}>
          <Stepper activeStep={state.integrationData.integrationStepperStep} alternativeLabel className={classes.customStepper}>
            {state.integrationData.integrationStepperSteps.map(label => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Grid>
        <Grid item style={{flex:"0 0 300px", textAlign: "right"}}>
          {nextButton()}
        </Grid>
      </Grid>

      {/* Closing dialog */}
      <Dialog
        open={openDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Are you sure you want to leave?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            By leaving, you won't keep any of the data saved.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button size="large" onClick={()=>{setOpenDialog(false)}} color="primary">No</Button>
          <Button size="large" component={Link} to="/admin/my-calcumate" color="primary">Yes</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default IntegrationStepper
