/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import styles from "assets/jss/material-dashboard-react/components/footerStyle.js";
import { Grid, Typography } from "@material-ui/core";
import saaLogo from "assets/img/icon-saa@2x.png"
import cmLogo from "assets/img/cm-isotype@2x.png"
import { Link } from "react-router-dom";
import { whiteColor } from "assets/jss/material-dashboard-react";


const useStyles = makeStyles(styles);

const Footer = props => {
  const classes = useStyles();
  return (
    <footer className={classes.footer}>
      <Grid container className={classes.footerContainerOne} spacing={3} direction="row" justify="space-between" alignItems="center">
        <Grid item xs={3} md={3}>
          <img src={saaLogo} style={{maxWidth:"100%", height:"auto"}}/>
        </Grid>
        <Grid item xs={9} md={9}>
          <Typography variant="subtitle2" component="div" className={classes.startFreeTxt}>Winner of the Innovation Award at the 2019 SSAA annual awards for excellence</Typography>
        </Grid>
      </Grid>
      <Grid container className={classes.footerContainerTwo} direction="row" justify="space-between" alignItems="center">
        <Grid item>
          <img src={cmLogo} />
          <Typography variant="subtitle2" component="span" className={classes.startFreeTxtWhite}>©2020 Calcumate</Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle2" component="span" className={classes.startFreeTxtWhite}>Privacy policy | <Link to="/terms-of-service#" style={{color:whiteColor}}>Terms of service</Link></Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle2" component="span" className={classes.startFreeTxtWhite}>Made with &hearts; in Australia</Typography>
        </Grid>
      </Grid>
    </footer>
  );
}

export default Footer
