import {
  whiteColor,
  primaryColour
} from "assets/jss/material-dashboard-react.js"

const homeHeaderStyle = {
  headerBackground: {
    backgroundColor: 'transparent',
    color: whiteColor,
    position: 'fixed',
    top: '0',
    zIndex: 200,
    width: "100%",
    minWidth: 992,
    margin: "0 auto",
  },
  onScrolling: {
    "& > .MuiGrid-container": {
      backgroundColor: whiteColor,
      paddingTop: 15,
      paddingRight: 45,
      paddingBottom: 13,
      paddingLeft: 45,
      boxShadow: "0 2px 20px rgba(0,0,0,0.1)"
    }
  },
  headerContainer:{
    width: "100%",
    margin: "0 auto",
    padding: 45,
    transition: "all 0.4s",
  },
  standardLink: {
    textTransform: "uppercase",
    textDecoration: "none",
    paddingTop:10,
    paddingBottom:10,
    display: "inline-block",
    fontSize: "0.875rem",
    fontFamily: "futura-pt, sans-serif",
    fontWeight: 500,
    lineHeight: 1.57,
    "&:hover": {
      color:primaryColour[200] + "!important"
    }
  },
  whiteLink: {
    color: whiteColor + "!important",
  },
  whiteLogo: {
    filter: "brightness(2000%)"
  },
  withSeparator: {
    "&:after": {
      display:"inline-block",
      content:`" "`,
      width: 1,
      height: 17,
      backgroundColor: whiteColor,
      margin:"0 30px",
      verticalAlign: "middle"
    }
  },
  navSeparator: {
    fontWeight: 600,
    display: "inline-block",
    marginLeft:30,
    marginRight:30,
    color: primaryColour[900]
  },
  ml3: {
    marginLeft: 30
  },
  ml2: {
    marginLeft: 17
  }
}

export default homeHeaderStyle
