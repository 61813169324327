import React, { useContext, useEffect, useState } from "react"

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles"
import { Grid, TextField, Button, Typography, Divider, FormControl, InputLabel, Select, MenuItem, Switch, Popover } from "@material-ui/core"
import HelpRounded from "@material-ui/icons/HelpRounded"

// Core components
import { useGlobalState } from "hooks/useGlobalState"

// Other components

// Style
import styles from "assets/jss/material-dashboard-react/layouts/integrationStyle"

const useStyles = makeStyles(styles)

const OptionStepAdvanced = props => {
  const classes = useStyles();
  const dispatchState = useContext(useGlobalState.Dispatch)
  const state = useContext(useGlobalState.State)
  const { theRef } = props
  const [ctaUrlPop, setCtaUrlPop] = useState(null)

  /* eslint-disable */
  useEffect(() => {
    theRef.current.scrollTop = 0 //scrollTop back to 0 when changing views
  }, []);
  /* eslint-enable */

  const qtyURL = () => { return state.integrationData.integrationIncQty ? "cm-qty={qty}" : "" }
  const idURL = () => { return state.integrationData.integrationIncID ? "cm-id={id}" : "" }
  const qryURL = () => { return state.integrationData.integrationIncID || state.integrationData.integrationIncQty ? "?" : "" }
  const midURL = () => { return state.integrationData.integrationIncID && state.integrationData.integrationIncQty ? "&" : "" }
  
  return (
    <Grid spacing={3} container>
      <Grid item xs={12}>
        <Typography variant="h3" component="h2">Call to action</Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider className={classes.customDividerOne} />
        <Typography variant="subtitle1" component="h2" className={classes.subHeadOne}>Call to action URL <HelpRounded style={{verticalAlign:"sub", fontSize:"1.3rem"}} onClick={(event)=>{setCtaUrlPop(event.currentTarget)}}/></Typography>
        <Popover
            id="ctaPopover"
            open={Boolean(ctaUrlPop)}
            anchorEl={ctaUrlPop}
            onClose={()=>{setCtaUrlPop(false)}}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
          <Typography variant="body2" className={classes.blueText}>Link on the button displayed on the calculation result.</Typography>
        </Popover>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          spacing={1}
        >
          <Grid item>
            <Typography variant="subtitle2">Use phone number instead of a URL</Typography>
          </Grid>
          <Grid item>
            <Switch
              checked={state.integrationData.integrationCTAType}
              onChange={()=>{dispatchState({ type: 'integration-data', payload: { integrationCTAType: !state.integrationData.integrationCTAType } })}}
              color="primary"
              name="cta-type"
              inputProps={{ 'aria-label': 'CTA type' }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="p-integration-cta-url-2"
              label={state.integrationData.integrationCTAType ? "Phone number" : "URL"}
              variant="outlined"
              type="text"
              helperText={state.integrationData.integrationCTAType ? "Example: 1234 567 899" : "Example: https://yourwebsite.com/book-now"}
              onChange={(e)=>{dispatchState({ type: 'integration-data', payload: { integrationCtaURL: e.target.value } })}}
              value={state.integrationData.integrationCtaURL}
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider className={classes.customDividerOne} />
        <Typography variant="subtitle1" component="h2" className={classes.subHeadOne}>CTA URL Params</Typography>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography variant="subtitle2">Include unit quantity</Typography>
          </Grid>
          <Grid item>
            <Switch
              checked={state.integrationData.integrationIncQty}
              onChange={()=>{dispatchState({ type: 'integration-data', payload: { integrationIncQty: !state.integrationData.integrationIncQty } })}}
              color="primary"
              name="include-unit-quantity"
              inputProps={{ 'aria-label': 'Include unit quantity' }}
            />
          </Grid>
        </Grid>
        <Divider className={classes.customDividerTwo} />
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography variant="subtitle2">Include unit ID</Typography>
          </Grid>
          <Grid item>
            <Switch
              checked={state.integrationData.integrationIncID}
              onChange={()=>{dispatchState({ type: 'integration-data', payload: { integrationIncID: !state.integrationData.integrationIncID } })}}
              color="primary"
              name="include-unit-id"
              inputProps={{ 'aria-label': 'Include unit ID' }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider className={classes.customDividerOne} />
        <Typography variant="subtitle1" component="h2" className={classes.subHeadOne}>CTA Link</Typography>
        <TextField
          id="p-integration-finalURL"
          label="Example URL"
          variant="outlined"
          type="text"
          disabled
          value={state.integrationData.integrationCtaURL + qryURL() + qtyURL() + midURL() + idURL()}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="p-integration-CTA-label"
          label="CTA Label"
          variant="outlined"
          type="text"
          helperText={`${state.integrationData.integrationCTALabel.length} / 10 character limit`}
          inputProps={{ maxLength: 10 }}
          value={state.integrationData.integrationCTALabel}
          onChange={(e)=>{dispatchState({ type: 'integration-data', payload: { integrationCTALabel: e.target.value } })}}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
            <InputLabel htmlFor="p-integration-wTarget">CTA Open</InputLabel>
            <Select
              id="p-integration-wTarget"
              value={state.integrationData.integrationCTATarget}
              variant="outlined"
              label="CTA Open"
              onChange={(e)=>{dispatchState({ type: 'integration-data', payload: { integrationCTATarget: e.target.value } })}}
            >
              
                <MenuItem key={0} value="_self">Same tab</MenuItem>
                <MenuItem key={1} value="_blank">New tab</MenuItem>
                <MenuItem key={2} value="_top">New window</MenuItem>
            </Select>
          </FormControl>
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          className={classes.buttonSpacing}
          color="primary"
          size="large"
          onClick={()=>{dispatchState({ type: "integration-data", payload: { integrationSecPanel: false } })}}
          fullWidth
        >Save</Button>
      </Grid>
    </Grid>
  )
}

export default OptionStepAdvanced
