import React from "react"

// @material-ui/core
// import { makeStyles } from "@material-ui/core/styles"
import { Typography } from "@material-ui/core"

// Core components

// Other components

// Style
// import styles from "assets/jss/material-dashboard-react/layouts/transactionalStyle"
// const useStyles = makeStyles(styles)

const RecoveryRequested = () => {
  // const classes = useStyles()
  return (
    <Typography variant="h5" component="p" align="center">We’ve sent you an email to reset your password</Typography>
  )
}

export default RecoveryRequested
