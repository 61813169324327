/*eslint-disable*/
import React, { useContext } from "react"

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import { Grid, TextField, Button, Typography, TableContainer, Table, TableBody, TableRow, TableCell, Paper } from "@material-ui/core"

// core components
import { Link } from "react-router-dom"
import { useGlobalState } from "hooks/useGlobalState"

// Other components
import AdminTransactionalHeader from "components/AdminTransactionalHeader/AdminTransactionalHeader"

// Style
import styles from "assets/jss/material-dashboard-react/layouts/transactionalStyle"

const useStyles = makeStyles(styles)

const AdminMySubscriptionConfirmed = props => {
  const classes = useStyles();
  let state = useContext(useGlobalState.State)
  
  return (
    <div>
      <Grid container spacing={0} justify="center" className={classes.cFormContaner}>
        <Grid item xs={12} sm={12} md={12}>
          <AdminTransactionalHeader
            backBtn={() => {props.history.push("/admin/my-account/my-subscription")}}
            closeBtn={false}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} className={classes.cWidthLimiter}>
          <Typography variant="overline">Subscription Cancellation</Typography>
          <Typography variant="h1" className={classes.headerTwoLine}>{state.currentPlan} ${state.currentPlanPrice}{state.currentPlanPriceRec}</Typography>
          <Typography variant="h5" component="h2" style={{margin:"40px 0"}}>Are you sure you want to cancel your current subscription?</Typography>
          <Grid container spacing={3} justify="center">
            <Grid item xs={6} sm={6} md={6}>
              <Button
                component={Link}
                to="/admin/my-account/my-subscription"
                variant="outlined"
                className={classes.buttonSpacing}
                color="primary"
                size="large"
                fullWidth
              >
                No
              </Button>
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
              <Button
                component={Link}
                to="/admin/my-account/my-subscription/cancelled"
                variant="contained"
                className={classes.buttonSpacing}
                color="primary"
                size="large"
                fullWidth
              >
                Yes
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      
    </div>
  );
}

export default AdminMySubscriptionConfirmed
