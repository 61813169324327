import React, { useState } from "react"

// core components
import { FormControl, InputLabel, InputAdornment, IconButton, OutlinedInput } from "@material-ui/core"
import VisibilityOff from "@material-ui/icons/VisibilityOff"
import Visibility from "@material-ui/icons/Visibility"

const PasswordInput = props => {
  const { uniqueID, uniqueLabel } = props
  let [values, setValues] = useState({
    amount: '',
    password: '',
    weight: '',
    weightRange: '',
    showPassword: false
  })
  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value })
  }
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword })
  }
  const handleMouseDownPassword = event => {
    event.preventDefault()
  }
  
  return (
    <FormControl
      variant="outlined"
      fullWidth>
        <InputLabel htmlFor={uniqueID}>{uniqueLabel}</InputLabel>
        <OutlinedInput
          id={uniqueID}
          type={values.showPassword ? 'text' : 'password'}
          value={values.password}
          onChange={handleChange('password')}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {values.showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
          labelWidth={70}
        />
    </FormControl>
  );
}

export default PasswordInput
