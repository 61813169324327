import React, { useContext } from "react"

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles"
import { Grid, Typography, TextField, Button } from "@material-ui/core"

// Core components
import { Link } from "react-router-dom"
import { useGlobalState } from "hooks/useGlobalState"

// Other components
import PasswordInput from "components/PasswordInput/PasswordInput"

// Style
import styles from "assets/jss/material-dashboard-react/layouts/transactionalStyle"
const useStyles = makeStyles(styles)

const Login = () => {
  const classes = useStyles();
  const dispatchState = useContext(useGlobalState.Dispatch)

  return (
    <Grid container spacing={0} justify="center" className={classes.cFormContaner}>
      <Grid item xs={12} sm={12} md={12} className={classes.cWidthLimiter}>
        <Typography variant="h1" className={classes.headerOneLine}>Login</Typography>
        <form noValidate autoComplete="off" className={classes.standardForm}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField id="p-login-email" label="Email" variant="outlined" type="email" fullWidth />
            </Grid>
            <Grid item xs={12}>
              <PasswordInput
                uniqueID="p-login-password"
                uniqueLabel="Password"
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                component={Link}
                to="/admin"
                variant="contained"
                className={classes.buttonSpacing}
                color="primary" size="large"
                onClick={()=>{dispatchState({ type: 'root-state', payload: { isLoggedIn: true } })}}
                fullWidth
              >
                LOGIN
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Typography align="center">
                <Link to="/password-request">Password Recovery</Link>
              </Typography>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
}

export default Login
