import React from "react"

// @material-ui/core
import { ThemeProvider } from "@material-ui/styles"
import { createMuiTheme } from "@material-ui/core"

// Core components
// import { createBrowserHistory } from "history"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"

// Other components
import themeStyle from "assets/jss/material-dashboard-react/themeStyle"
import Home from "layouts/Home"
import NotFound from "views/NotFound/NotFound"
import routes from "./routes";
import "assets/css/material-dashboard-react.css?v=1.8.0"
import { Dashboard } from "WrapperApp"

const AppRoute = ({component:Component, layout:Layout, ...rest}) => (
  <Route {...rest} render={props=>(
    <Layout><Component {...props} /></Layout>
  )} />
)

const App = (props) => {
  // const hist = createBrowserHistory()
  const theme = createMuiTheme(themeStyle)

  return (
    <Dashboard>
      <ThemeProvider theme={theme}>
        <Router>
          <Switch>
            <Route exact path="/" component={Home} />
            {routes.map((prop, key) => {
                return (
                  <AppRoute
                    exact
                    path={prop.path}
                    component={prop.component}
                    layout={prop.layout}
                    key={key}
                  />
                );
            })}
            <Route component={NotFound} status={404} />
          </Switch>
        </Router>
      </ThemeProvider>
    </Dashboard>
  )
}

export default App
